import React, { useEffect, useRef, useState } from 'react'
import './index.css'

import { deleteDocument, getAllEmployeeslotfordate, getAllEmployeeTasks, getAllSlotsForChecking, getAllSlotsWhere, getuserinformation, saveitem, saveitemwithid, updateItem, updateNestedArrayItem, updateNestedItem } from '../../utils/firebasefunctions';
import { useLocation, useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { FaUser } from 'react-icons/fa';
import { CiCirclePlus } from 'react-icons/ci';
import { FaCircleMinus } from 'react-icons/fa6';
import moment from 'moment';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { RotatingLines } from 'react-loader-spinner'
import { arrayUnion } from 'firebase/firestore';
import { IoIosRadioButtonOn, IoIosRadioButtonOff } from "react-icons/io";
import { IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import { FaDollarSign } from 'react-icons/fa';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DashboardContainer from '../components/Dashboard-container';
import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { RiGalleryView2 } from "react-icons/ri";
import { RiGalleryView } from "react-icons/ri";
import EmployeePopup from '../components/EmployeePopup';
import DesignationPopUp from '../components/DesignationPopUp';

function CreateEmployeeSchedule() {
  const [{ user, employees }, dispatch] = useStateValue();
  const location = useLocation()
  const [projectid, setprojectid] = useState(location.state?.projectid ? location.state.projectid : user?.id);
  const [selectedemployee, setselectedemployee] = useState(location.state?.employeedata ? location.state.employeedata : "");
  const [Location, setLocation] = useState("");
  const [startdate, setstartdate] = useState(new Date());
  const [starttime, setstarttime] = useState("10:00")
  const [endtime, setendtime] = useState("19:00")
  const [lunchstarttime, setlunchstarttime] = useState("13:00")
  const [lunchendtime, setlunchendtime] = useState("14:00")
  const [enddate, setenddate] = useState(new Date());
  const [designation, setdesignation] = useState("");
  const [designationoptions, setdesignationoptions] = useState([]);
  const [employeepopup, setemployeepopup] = useState(false);
  const [designationpopup, setdesignationpopup] = useState(false);
  const [lunchenabled, setlunchenabled] = useState(false);
  const [fixedprice, setfixedprice] = useState(0);
  const [loading, setloading] = useState(false);
  const [slots, setslots] = useState([]);
  const [tasks, settasks] = useState([]);
  const [jobs, setjobs] = useState([]);
  const [value, onChange] = useState(new Date());
  const [createslot, setcreateslot] = useState(true);
  const [slotdeletionloader, setslotdeletionloader] = useState(false);
  const [slotdeletionindex, setslotdeletionindex] = useState(0);




  useEffect(() => {
    if (createslot == false) {
      getallprojectslotsforselecteddate()
    }
  }, [createslot, value])
  // useEffect(() => {
  //   if (selectedemployee.id != undefined) {
  //     getallemployeetasks()
  //     getallemployeejobs()
  //   }
  // }, [selectedemployee])

  useEffect(() => {
    getallprojectdesignations()
  }, [projectid])





  const getallprojectslotsforselecteddate = async () => {
    await getAllEmployeeslotfordate("slots", "projectid", "==", projectid, "sheduleddate", "==", moment(value).format("YYYY-MM-DD")).then(async (response) => {
      let responsedata = [...response]
          for (const index in responsedata) {


            responsedata[index]["name"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.firstname + " " + data.lastname })
            responsedata[index]["designation"] =employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.projectdesignation })
            responsedata[index]["profilepicture"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.profilepicture })

          }

      setslots(responsedata)
    }).catch((err) => {
      alert(err)
    })
  }

  const deleteslot = async (item, index) => {
    setslotdeletionindex(index)
    setslotdeletionloader(true)
    await deleteDocument("slots", item.schedulecreationdate).then(async (response) => {
if(response){
let data = [...slots]
data = data.filter((_, indexdata) => indexdata !== index);
setslots(data)
  toast.success("Slot Deleted Successfully!")
  setslotdeletionloader(false)
}else{
  toast.error("Your slot can not be deleted at the moment. Please try again later!")
}

    }).catch((err) => {
      setslotdeletionloader(false)
      toast.error("Your slot can not be deleted at the moment. Please try again later!")
      alert(err)
    })
  }

    const newslotcreatedsuccess = async (employeeid, timeslot) => {
      try {
        const res = await fetch('https://us-central1-raftekpro-82bb0.cloudfunctions.net/api/newslot', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: employeeid,
            timeslot: timeslot
          })
        });
if(res.status == 200){
  console.log('Notififcation ' + JSON.stringify(res))
  const data = await res.json();
  console.log(data)
   console.log('Successfully Notified User')
}else{

  console.log('Notififcation Error' + JSON.stringify(res))
}

      } catch (error) {
        console.error('Error:', error);

      }
    };

    const convertToTimestamp = (timeString) => {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(':').map(Number);

      // Create a new Date object for the current day
      const now = new Date();

      // Set the hours and minutes based on the time string
      now.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, milliseconds to 0

      // Return the Unix timestamp (milliseconds since January 1, 1970)
      return now.getTime();
    };

  const createschedule = async () => {
    if (selectedemployee == "") {

      toast.warn("Please select an employee!")
    } else if (designation == "") {
      toast.warn("Please select an designation!")
    } else if (enddate < startdate) {
      toast.warn("Shift End date must be grater than Shift Start date!")
    } else if (starttime == undefined) {
      toast.warn("Please select a starting time!")
    } else if (endtime == undefined) {
      toast.warn("Please select an ending time!")
    } else if (endtime < starttime) {
      toast.warn("Shift End time must be grater than Shift Start time!")
    } else if (fixedprice == 0) {
      toast.warn("Enter per hour price")
    }
    else if (lunchenabled == true && lunchstarttime == undefined) {
      toast.warn("Please select a lunch starting time!")
    } else if (lunchenabled == true && lunchendtime == undefined) {
      toast.warn("Please select a lunch ending time!")
    } else if (lunchenabled == true && lunchendtime < lunchstarttime) {
      toast.warn("Lunch End time must be grater than Lunch Start time!")
    }

    else {



      setloading(true)

      let dates = [];
      let currentDate = new Date(startdate);
      while (currentDate <= enddate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      let formatteddates = []

      for (const key in dates) {
        formatteddates.push(moment(dates[key]).format("YYYY-MM-DD"),);
      }
await getuserinformation("ApprovedLeaves", selectedemployee.id).then(async (responseleaves) => {
  // alert(JSON.stringify(responseleaves.leaves))
  const matches = responseleaves.leaves.filter(item => formatteddates.includes(item));
  if (matches.length > 0) {
    toast.error("Your selected time slots colide with the employees leave schedule. Please change selected timeslot or date.")
    setloading(false)
}else{
  let redundanttimeslot = false
  await getAllSlotsForChecking("slots", "sheduleddate", "in", formatteddates, "employeeid", "==", selectedemployee.id).then(async (response) => {
    for (const indexkey in response) {
      let slotstarttime = moment(new Date(`${response[indexkey].sheduleddate} ${response[indexkey].starttime}`))
      let slotendtime = moment(`${response[indexkey].sheduleddate} ${response[indexkey].endtime}`)

      let selectedstarttime = moment(new Date(`${response[indexkey].sheduleddate} ${starttime}`))
      let selectedendtime = moment(`${response[indexkey].sheduleddate} ${endtime}`)



      if ((selectedstarttime >= slotstarttime && selectedstarttime <= slotendtime) || (selectedendtime >= slotstarttime && selectedendtime <= slotendtime) || (selectedstarttime <= slotstarttime && selectedendtime >= slotendtime)) {
        redundanttimeslot = true
      }

    }



    if (redundanttimeslot == true) {
      toast.error("Your selected time slots colide with the employees preexisting schedule. Please change selected timeslot or date.")
      setloading(false)
    } else {
      toast.success("Creating Schedule!")
      let slotid = Date.now()

      for (const key in formatteddates) {




        let dateid = formatteddates[key]
        let data = {
          schedulecreationdate: Date.now(),
          sheduleddate: dateid,
          // allprojectdates: formatteddates,
          slotid: slotid,
          employeeid: selectedemployee.id,
          status: "Pending",
          projectdesignation: designation,
          timeslot: starttime + " - " + endtime,
          starttime: starttime,
          starttimetimestamp: convertToTimestamp(starttime),
          lunchallowed: lunchenabled,
          lunchstarttime: lunchstarttime,
          lunchendtime: lunchendtime,
          endtime: endtime,
          endtimetimestamp: convertToTimestamp(endtime),
          minutes: 0,
          late: false,
          projectid: projectid,
          priceperhour: fixedprice
        }

        await saveitemwithid("slots", data.schedulecreationdate.toString(), data,).then(async (response) => {
          setselectedemployee("")
          setdesignation("")
          setstartdate(new Date())
          setenddate(new Date())
          setstarttime("10:00")
          setendtime("19:00")
          setfixedprice(0)
          setloading(false)

if(key == formatteddates.length -1){
newslotcreatedsuccess(selectedemployee.id, starttime + " - " + endtime)
toast.success("Schedule created Successfully!")
}
        }).catch((err) => {
          console.log(err)
          setloading(false)
          toast.error("Something went wrong while posting your Schedule. Please try again later.")
        })





      }








    }

  }).catch((err) => {
    alert("this is ierror" + err)
    setloading(false)
    toast.error("Something went wrong while posting your Schedule. Please try again later.")
  })
  setloading(false)
}
}).catch((err) => {
  setloading(false)
  toast.error("Something went wrong while posting your Schedule. Please try again later.")
})










    }
  }

  const getallprojectdesignations = async () => {
    await getuserinformation("users", projectid).then(async (response) => {
      if (response.designation != undefined) {
        setdesignationoptions(response.designation)
      }



    }).catch((err) => {
      alert(err)
    })
  }

  const getallemployeetasks = async () => {
    await getAllEmployeeTasks("tasks", "employeeid", "==", selectedemployee.id).then(async (response) => {
      settasks(response)

    }).catch((err) => {
      alert(err)
    })
  }

  const getallemployeejobs = async () => {
    await getAllEmployeeTasks("jobs", "assigned", "==", selectedemployee.id).then(async (response) => {
      setjobs(response)
    }).catch((err) => {
      alert(err)
    })
  }

  const handlefixedprice = (event) => {
    setfixedprice(event.target.value)
  }


  return (
    <div className='min-h-screen mt-[80px] lg:mt-[100px] flex flex-col items-center'>
   <div className='flex  justify-start w-[90%] bg-white mx-auto mb-[20px] '>
{/* {createslot ?
   <RiGalleryView

onClick={() => {
  // navigate('/UpdateSlot');
setcreateslot(false)

}} color='grey' size={'20'} className={`mt-1.5 hover:cursor-pointer`} />
:
<FaPlus

onClick={() => {
  // navigate('/UpdateSlot');
setcreateslot(true)

}} color='green' size={'20'} className={`mt-1.5 hover:cursor-pointer`} />} */}

<div className='flex flex-row items-center'>
          <p
            onClick={() => { setcreateslot(true) }}
            className={`PoppinsRegular text-[14px] font-weight: 400  hover:cursor-pointer hover:underline  ${createslot ? "text-themeColor font-medium text-[15px]" : "text-black"}`} >Create</p>
          <p className='mx-[10px]'>|</p>
          <p
            onClick={() => { setcreateslot(false) }}
            className={`PoppinsRegular text-[14px] font-weight: 400  hover:cursor-pointer hover:underline  ${!createslot ? "text-themeColor font-medium text-[15px]" : "text-black"} `}>View All</p>
        </div>
</div>

      <div className='w-[90%]  px-3 lg:px-7 rounded-xl  py-[20px] bg-white  drop-shadow-lg mx-auto  lg:mb-[30px] '>

{!createslot &&
<>
      <div className='w-full h-full flex items-center'>
              <Calendar className='text-base w-full' onChange={onChange} value={value} />
            </div>

            </>
}
{createslot &&
<>
        {selectedemployee ?
          <>
            <div className='flex flex-col items-center justify-center'>
              {selectedemployee.profilepicture ?
                <img src={selectedemployee.profilepicture} className='w-[110px] h-[110px] object-cover rounded-full' />
                : <FaUser

                  className="text-lighttextGray/80 border border-gray-300 rounded-full w-[110px] h-[110px]"
                />}

              <p className='PoppinsRegular font-medium text-textcolor mt-[10px]'>{selectedemployee.firstname + " " + selectedemployee.lastname}</p>
              <p className='PoppinsRegular font-medium text-lightgrey text-[12px]'>{selectedemployee.designation}</p>




            </div>
            {/* <div className='flex flex-row items-center my-[20px]'>
    <text className='PoppinsRegular font-medium text-textcolor'>Work Schedule:</text>
<text className='ml-[10px] PoppinsRegular font-medium text-textcolor'>{selectedemployee.starttime + " - " + selectedemployee.endtime}</text>

</div> */}
          </>
          : null}

        <div className='flex flex-col  items-center justify-between w-full md:mt-[20px]'>
          <div className='flex flex-col  justify-between   w-full'>

            <div className='w-full  mt-5 md:mt-0'>
              <div

                className='w-full relative' >
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Select Employee</p>
                <div
                     onClick={() => setemployeepopup(!employeepopup)}
                  className={`px-4 ${selectedemployee?.firstname == undefined ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full h-[45px] rounded-xl border border-themeColor items-center flex`}>{selectedemployee?.firstname == undefined ? "Click to select" : selectedemployee?.firstname + " " + selectedemployee?.lastname}</div>


                  <EmployeePopup
                  style={{top: "80px"}}
                                visible ={employeepopup}
                                   data = {employees.filter(function (data) { return data?.projectassigned?.find(dataset => dataset.project == projectid) != undefined  })}
                                activateoutsidecontainerclick = {()=>{
                                  setemployeepopup(false);
                                }}

                              onClick={(item, index) => { setselectedemployee(item) }}
                              />
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-between mt-[30px]  w-full '>
            <div className='w-full  md:mt-0'>
              <div

                className='w-full relative' >
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Designation</p>
                <div
                    onClick={() => setdesignationpopup(!designationpopup)}
                  className={`px-4 ${designation == "" ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full  h-[45px] rounded-xl border border-themeColor items-center flex`}>{designation == "" ? "Click to select" : designation}</div>


                  <DesignationPopUp
                  style={{top: "80px"}}
                                visible ={designationpopup}
                                data = {designationoptions}
                                activateoutsidecontainerclick = {()=>{
                                  setdesignationpopup(false);
                                }}
                                onClick={(item, index) => {  setdesignation(item.designation)
                                  setdesignationpopup(false);
                                 }}

                              />
              </div>
            </div>
          </div>


          <div className='flex flex-col justify-between mt-[30px]  w-full md:mx-[40px]'>
            <div className='w-full '>
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Start Date</p>
              <DatePicker
                minDate={new Date()}
                className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={startdate} onChange={(date) => setstartdate(date)} />
            </div>

          </div>
          <div className='flex flex-col  justify-between mt-[30px]  w-full'>
            <div className='w-full '>
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>End Date</p>
              <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={enddate} onChange={(date) => setenddate(date)} />
            </div>

          </div>
        </div>

        <div className='flex flex-col w-full  mt-[30px] '>
          <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Lunch Break</p>
          <div className='flex flex-row items-center justify-start mt-[5px]'>
            <div
              onClick={() => { setlunchenabled(true) }}
              className='flex flex-row items-center justify-center hover:cursor-pointer'>
              <p className='PoppinsRegular text-textcolor text-sm font-medium '>Yes</p>
              {lunchenabled ?
                <IoRadioButtonOn

                  className=" ml-[5px] text-themeColor border border-gray-300 rounded-full w-[20px] h-[20px]"
                /> :
                <IoRadioButtonOff

                  className="ml-[5px] text-white border border-gray-300 rounded-full w-[20px] h-[20px]"
                />}
            </div>
            <div
              onClick={() => { setlunchenabled(false) }}
              className='flex flex-row items-center justify-center ml-[15px] hover:cursor-pointer'>
              <p className='PoppinsRegular text-textcolor text-sm font-medium '>No</p>
              {lunchenabled ?
                <IoRadioButtonOff

                  className="ml-[5px] text-white border border-gray-300 rounded-full w-[20px] h-[20px]"
                />
                :
                <IoRadioButtonOn

                  className=" ml-[5px] text-themeColor border border-gray-300 rounded-full w-[20px] h-[20px]"
                />}
            </div>
          </div>

        </div>


        {lunchenabled &&
          <div className='flex flex-col  items-center justify-center w-full'>
            <div className='flex flex-col w-full  mt-[20px] '>
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Lunch Start Time</p>

              <TimePicker className='rounded-xl border border-themeColor w-full   h-[45px] ' onChange={setlunchstarttime} value={lunchstarttime} />

            </div>
            <div className='flex flex-col  mt-[30px]  w-full '>
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Lunch End Time</p>

              <TimePicker className='  rounded-xl border border-themeColor  w-full h-[45px] ' onChange={setlunchendtime} value={lunchendtime} />

            </div>
          </div>}
        <div className='flex flex-col  items-center justify-center w-full'>
          <div className='flex flex-col w-full  mt-[30px] '>
            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Shift Start Time</p>

            <TimePicker className='rounded-xl border border-themeColor w-full   h-[45px] ' onChange={setstarttime} value={starttime} />

          </div>
          <div className='flex flex-col  mt-[30px]  w-full '>
            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Shift End Time</p>

            <TimePicker className='  rounded-xl border border-themeColor  w-full h-[45px] ' onChange={setendtime} value={endtime} />

          </div>
        </div>
        <div className='w-full mt-[20px]' >
          <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Price</p>
          <div className='w-full py-[6px] rounded-xl border border-themeColor items-center flex flex-row'>
            <div className='pl-5'>
              <FaDollarSign />
            </div>
            <input type='text'
              inputMode='numeric'
              value={fixedprice}
              placeholder={'Price'}
              onChange={handlefixedprice}
              className="p-[0px] text-textcolor text-sm font-medium PoppinsRegular  w-[95%] lg:w-[97%]  px-[17px] lg:px-4 border-none items-center flex"
            />

            <p className='text-textcolor  text-lg pr-2 '>/hr</p>
          </div>
        </div>
        {/* <div className='w-full mt-[20px] PoppinsRegular' >
          <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Location</p>

          <GooglePlacesAutocomplete
            apiKey='AIzaSyBQITYyVOR2eVeTha4Vzfc2T8S0ZAcENDE'

            selectProps={{
              Location,
              onChange: setLocation,
              placeholder: "Select your address",

            }}
            name="address"
            value={Location}
          />


        </div> */}
        {/* <div className='mt-[60px]'>

{slots.map((item, index) =>
                 (
         <div className='flex flex-row justify-start items-center mb-[10px]'>
         <FaCircleMinus
onClick={()=>{
    let data = [...slots]
    data.splice(index, 1)
    setslots(data)
}}
className=" text-red-500 w-[20px] h-[20px] hover:cursor-pointer"
/>
             <div

             className='mx-[10px] w-full' >

             <div
             className={`relative px-4 ${item.project == "" ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full h-[45px] rounded-xl border border-themeColor items-center flex`}>{item.project == "" ? "Select Task/Project" : item?.project}
                   <div className='position absolute z-10 flex flew-row right-[15px]  '>
                <text
                onClick={()=>{

                    let data = [...slots]

                    data[index].tasksvisible =   !data[index].tasksvisible
                    data[index].jobsvisible =   false
                    setslots(data)
                }}
                className='text-sm font-medium PoppinsRegular hover:cursor-pointer hover:text-themeColor'>Tasks</text>
                <text className='text-sm font-medium PoppinsRegular px-[5px]'> | </text>
                <text
                   onClick={()=>{
                    let data = [...slots]
                    data[index].jobsvisible =   !data[index].jobsvisible
                    data[index].tasksvisible =   false
                    setslots(data)
                }}
                className='text-sm font-medium PoppinsRegular hover:cursor-pointer  hover:text-themeColor'>Jobs</text>
             </div>
             </div>

             {item.tasksvisible &&
             <div className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
             {tasks.map((iteminner, indexinner) =>
                 (
                     <div
                     onClick={()=>{
                        let data = [...slots]
                        data[index].project = iteminner.title
                        data[index]["id"] = iteminner.taskcreationdate
                        data[index].tasksvisible = false
                        setslots(data)

                                             }}
                     className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-[#0989B826] hover:rounded-md`}>

                         <text className='text-textcolor font-medium PoppinsRegular text-sm ml-[10px]'>{iteminner.title}</text>
                     </div>
                 ) )}

             </div>}
             {item.jobsvisible &&
             <div className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
             {jobs.map((iteminner, indexinner) =>
                 (
                     <div
                     onClick={()=>{
let data = [...slots]
data[index].project = iteminner.title
data[index]["id"] = iteminner.jobcreationdate
data[index].jobsvisible = false
setslots(data)

                     }}
                     className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-[#0989B826] hover:rounded-md`}>

                         <text className='text-textcolor font-medium PoppinsRegular text-sm ml-[10px]'>{iteminner.title}</text>
                     </div>
                 ) )}

             </div>}
             </div>
             <div
             onClick={()=> {
                let data = [...slots]
                data[index].hoursvisbile =   !data[index].hoursvisbile
                setslots(data)
             }}
             className='w-[20%] md:w-[10%] flex flex-row justify-center items-end' >

             <div
             className={`px-4 text-textcolor text-sm font-medium PoppinsRegular w-full h-[45px] rounded-xl border border-themeColor justify-center items-center flex`}>{item.hours  }</div>
             {item.hoursvisbile &&
             <div className='w-[50px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
             {hours.map((inneritem, innerindex) =>
                 (
                     <div
                     onClick={()=> {
                        let data = [...slots]
                        data[index].hours = inneritem.hours
                        setslots(data)
                     }}
                     className={`flex flex-row items-center justify-center  w-full py-[5px] cursor-pointer hover:bg-[#0989B826] hover:rounded-xl`}>

                         <text className='text-textcolor font-medium PoppinsRegular text-sm'>{inneritem.hours}</text>
                     </div>
                 ) )}
             </div>}
             <text className='text-sm font-medium PoppinsRegular ml-[10px]'>hr's</text>

             </div>




             </div>
                 ))}
</div> */}

        {/* <div
onClick={()=>{
    let object ={
 project: "",
hours: 0,
hoursvisible: false,
tasksvisible: false,
jobsvisible: false
    }

    let data  = [...slots]
    data.push(object)
setslots(data)
}}
className='w-full flex justify-center my-[100px] flex-col items-center hover:cursor-pointer'>
        <CiCirclePlus

className="text-lighttextGray/80 w-[60px] h-[60px]"
/>
<text className='font-semibold PoppinsRegular text-lighttextGray/80'>Add time slot</text>
</div> */}

        <div className='w-full flex items-center justify-center mt-[30px] '>
          {loading ?
            <div
            className=" py-4  mt-6 w-full flex justify-center   "
            >
              <RotatingLines
                visible={true}
                height="25"
                width="25"
                color="grey"
                strokeColor="#218fdb"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>

            :
            <button
              onClick={() => {
                createschedule()
              }}
              type='submit' className='text-center py-4 rounded-2xl  bg-themeColor hover:bg-themeColor/80 w-full  text-white text-sm font-semibold PoppinsRegular uppercase '>
              Set Schedule
            </button>
          }
        </div>
        </>}
      </div>
      {!createslot &&
      <>
      <table className="  w-[90%]  divide-y-2 divide-gray-200 text-sm mt-[20px]   px-3 lg:px-7">
                    <thead className="ltr:text-left rtl:text-right">
                      <tr>

                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 "></th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Date</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-10 "></th>

                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Employee Name</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Designation</th>
                      <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Status</th>

                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 text-center">
           { slots.map((item, index) => (

                          <tr
                          key = {index.toString()}
                          className='hover:bg-[#0989B826]/10 cursor-pointer'>
                            <td
                              className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium ">
                             {slotdeletionloader && slotdeletionindex == index ?

                                    <RotatingLines
                                      visible={true}
                                      height="15"
                                      width="15"
                                      color="grey"
                                      strokeColor="#218fdb"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      ariaLabel="rotating-lines-loading"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                    />
                             :

                              <MdDelete
                                onClick={() => {
                                  if(!slotdeletionloader){
                                    deleteslot(item, index)
                                  }

                                }}
                                className=" text-lighttextGray hover:text-red-600   w-[17px] h-[17px] hover:cursor-pointer"
                              />
                            }
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {moment(item.checkindate).format("DD-MM-YYYY")}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor flex justify-center ">
                              <img src={item.profilepicture} className='w-[35px] h-[35px]  object-cover rounded-full' />
                            </td>
                            {/* <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
      {item.employeeid}
    </td> */}


                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item.name}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor  ">
                              {item.projectdesignation}
                            </td>
                            <td className={`whitespace-nowrap px-4 py-2 PoppinsRegular font-medium  ${item.status == "Pending" ? " text-orange-500" : item.status == "Rejected" ? " text-red-600" : " text-green-500"  }`}>
                              {item.status}
                            </td>
                            {/* <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item.checkintime ? item.checkintime : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item.checkouttime ? item.checkouttime : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item?.minutes ? parseInt(item?.minutes / 60) : 0}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
                              onClick={() => {
                                // getlocationaddress(item?.checkinlatitude, item?.checkinlongitude)
                              }}>
                              {item?.checkinlatitude ? "View" : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
                              onClick={() => {
                                // getlocationaddress(item?.checkoutlatitude, item?.checkoutlongitude)
                              }}>
                              {item?.checkoutlatitude ? "View" : "Not Available"}
                            </td> */}
                          </tr>


                      ))}


                      </tbody>

                      </table>
                      {slots.length < 1 &&
                        <div className='w-full  flex justify-center items-center'>
                        <p className=' text-lightgrey whitespace-nowrap py-2 font-medium PoppinsRegular px-4 mt-[60px] text-[15px]'>No Slots Available</p>
                        </div>}
                        </>
}
    </div>
  );
}

export default CreateEmployeeSchedule;