import React, { useEffect, useRef, useState } from 'react'
import './index.css'

import { getAllEmployeeTasks, getAllSlotsForChecking, getAllSlotsWhere, getAllSlotsWherex3, getAllSlotsWherex4, getuserinformation, saveitem, saveitemnew, updateItem, updateNestedArrayItem, updateNestedItem } from '../../utils/firebasefunctions';
import { useFetcher, useLocation, useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { FaUser } from 'react-icons/fa';
import { CiCirclePlus } from 'react-icons/ci';
import { FaCircleMinus } from 'react-icons/fa6';
import moment from 'moment';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { RotatingLines } from 'react-loader-spinner'
import EmployeePopup from '../components/EmployeePopup';
import ProjectPopUp from '../components/ProjectPopUp';
import DesignationPopUp from '../components/DesignationPopUp';


function UpdateSlot() {
  const [{ user, employees, clients }, dispatch] = useStateValue();
  const location = useLocation()
  const [projectid, setprojectid] = useState(location.state?.projectid ? location.state.projectid : "");
  const [selectedemployee, setselectedemployee] = useState(location.state?.employeedata ? location.state.employeedata : "");
  const [Location, setLocation] = useState(location.state?.editdoc ? location.state.editdoc.location : "");
  const [slotedit, setslotedit] = useState(location.state?.editdoc ? location.state.editdoc : null);

  const [minutes, setminutes] = useState(0);
  const [startdate, setstartdate] = useState(new Date());
  const [manualdate, setmanualdate] = useState(new Date());
  const [starttime, setstarttime] = useState(location.state?.editdoc?.checkindatetime ? moment(location.state.editdoc.checkindatetime).format("HH:mm") : "10:00")
  const [endtime, setendtime] = useState(location.state?.editdoc?.checkoutdatetime ? moment(location.state.editdoc.checkoutdatetime).format("HH:mm") : "19:00")
  const [lunchstarttime, setlunchstarttime] = useState("13:00")
  const [lunchendtime, setlunchendtime] = useState("14:00")
  const [enddate, setenddate] = useState(new Date());
  const [designation, setdesignation] = useState(location.state?.projectdesignation ? location.state.projectdesignation : "");

  const [designationoptions, setdesignationoptions] = useState([]);
  const [employeepopup, setemployeepopup] = useState(false);
  const [designationpopup, setdesignationpopup] = useState(false);
  const [lunchenabled, setlunchenabled] = useState(false);
  const [projectpopup, setprojectpopup] = useState(false);
  const [selectedproject, setselectedproject] = useState("");




  const [loading, setloading] = useState(false);
  const [slots, setslots] = useState([]);
  const [tasks, settasks] = useState([]);
  const [jobs, setjobs] = useState([]);

  const [hours, sethours] = useState([
    {
      hours: 1
    },
    {
      hours: 2
    },
    {
      hours: 3
    },
    {
      hours: 4
    },
    {
      hours: 5
    },
    {
      hours: 6
    }, {
      hours: 7
    }, {
      hours: 8
    }, {
      hours: 9
    }, {
      hours: 10
    }, {
      hours: 11
    }, {
      hours: 12
    }, {
      hours: 13
    }, {
      hours: 14
    }, {
      hours: 15
    }, {
      hours: 16
    }, {
      hours: 17
    }, {
      hours: 18
    }, {
      hours: 19
    }, {
      hours: 20
    }, {
      hours: 21
    }, {
      hours: 22
    }, {
      hours: 23
    }, {
      hours: 24
    }]);



    const designationpopupref = useRef(null)


  const navigate = useNavigate();



  function calculateTimeDifferenceInMinutes(time1, time2) {
    // Helper function to convert "HH:MM" to minutes
    function timeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    }

    // Convert both times to minutes
    const minutes1 = timeToMinutes(time1);
    const minutes2 = timeToMinutes(time2);

    // Calculate the difference in minutes
    const diffInMinutes = Math.abs(minutes2 - minutes1);

    return diffInMinutes;
  }

  const createslot = async () => {


    if (selectedemployee == "") {

      toast.warn("Please select an employee!")
    } else if (selectedproject == "") {
      toast.warn("Please select a project!")
    } else if (designation == "") {
      toast.warn("Please select a designation!")
    } else if (starttime == undefined) {
      toast.warn("Please select a checkin time!")
    } else if (endtime == undefined) {
      toast.warn("Please select a checkout time!")
    } else if (endtime < starttime) {
      toast.warn("Checkout time must be grater than Checkin time!")
    }
    // else if (minutes <= 0) {
    //   toast.warn("Minutes must be greater than 0!")
    // }
    else {
      setloading(true)
      let editallowed = true
      // setloading(true)
      let slotid = Date.now()
      let dateid = moment(manualdate).format("YYYY-MM-DD")
      let data = {
        schedulecreationdate: Date.now(),
        checkindatetime: manualdate.getTime(),
        sheduleddate: dateid,
        slotid: slotid,
        employeeid: selectedemployee.id,
        projectdesignation: designation,
        projectid: projectid,
        manualhours: true,
        editedby: user?.id,
        status: 'Accepted',
        lunchallowed: false,
      }

      let checkindatetimedata = starttime?.split(':');
      const checkindatetime = new Date(moment(manualdate).format('YYYY-MM-DD'))
      checkindatetime.setHours(parseInt(checkindatetimedata[0], 10));
      checkindatetime.setMinutes(parseInt(checkindatetimedata[1], 10));

      data["checkindatetime"] = checkindatetime.getTime()
      data["checkintime"] = starttime
      data["starttime"] = starttime

      let checkoutdatetimedata = endtime?.split(':');
      const checkoutdatetime = new Date(moment(manualdate).format('YYYY-MM-DD'))
      checkoutdatetime.setHours(parseInt(checkoutdatetimedata[0], 10));
      checkoutdatetime.setMinutes(parseInt(checkoutdatetimedata[1], 10));

      data["checkoutdatetime"] = checkoutdatetime.getTime()
      data["checkouttime"] = endtime
      data["endtime"] = endtime
      let min = calculateTimeDifferenceInMinutes(starttime, endtime)

      data["minutes"] = min

      let lunchdiff = 0
      if (slotedit?.lunchallowed) {
        let lunchstartimedata = slotedit.lunchstarttime.split(':');
        const lunchstartdate = new Date(moment(manualdate).format('YYYY-MM-DD'));
        lunchstartdate.setHours(parseInt(lunchstartimedata[0], 10));
        lunchstartdate.setMinutes(parseInt(lunchstartimedata[1], 10));
        let lunchendtimedata = slotedit.lunchendtime.split(':');
        const lunchenddate = new Date(moment(manualdate).format('YYYY-MM-DD'));
        lunchenddate.setHours(parseInt(lunchendtimedata[0], 10));
        lunchenddate.setMinutes(parseInt(lunchendtimedata[1], 10));
        lunchdiff = Math.abs(lunchenddate - lunchstartdate);
      }
      let diff = Math.abs(checkoutdatetime - checkindatetime);
      diff = diff - lunchdiff
      data["minutes"] = diff / 1000 / 60
      data["edited"] = true
      data["editedby"] = user?.id
      await getAllSlotsWherex3("slots", "sheduleddate", "==", moment(manualdate).format('YYYY-MM-DD'), "employeeid", "==", selectedemployee.id, "status", "==", "Accepted").then(async (response) => {
        // console.log('starttime?.split', data, response);
        // return
        for (const key in response) {
          let responsecheckindatetimedata = response[key].starttime.split(':');
          const responsecheckindatetime = new Date(moment(manualdate).format('YYYY-MM-DD'))
          responsecheckindatetime.setHours(parseInt(responsecheckindatetimedata[0], 10));
          responsecheckindatetime.setMinutes(parseInt(responsecheckindatetimedata[1], 10));
          let responsecheckoutdatetimedata = response[key].endtime.split(':');
          const responsecheckoutdatetime = new Date(moment(manualdate).format('YYYY-MM-DD'))
          responsecheckoutdatetime.setHours(parseInt(responsecheckoutdatetimedata[0], 10));
          responsecheckoutdatetime.setMinutes(parseInt(responsecheckoutdatetimedata[1], 10));
          if ((checkindatetime >= responsecheckindatetime && checkindatetime <= responsecheckoutdatetime) || (checkoutdatetime >= responsecheckindatetime && checkoutdatetime <= responsecheckoutdatetime) || (checkindatetime <= responsecheckindatetime && checkoutdatetime >= responsecheckoutdatetime)) {
            editallowed = false
          }
        }
        if (editallowed) {

          await saveitemnew("slots", data,).then(async (response) => {
            setselectedemployee("")
            setdesignation("")
            setmanualdate(new Date())
            setselectedproject("")
            setminutes(0)
            setstarttime(location.state?.editdoc?.checkindatetime ? moment(location.state.editdoc.checkindatetime).format("HH:mm") : "10:00")
            setendtime(location.state?.editdoc?.checkindatetime ? moment(location.state.editdoc.checkindatetime).format("HH:mm") : "19:00")
            setloading(false)
            toast.success("Manual Hours Added Successfully!")
          }).catch((err) => {
            alert(err)
            setloading(false)
            toast.error("Something went wrong while adding Manual Hours. Please try again later.")

          })


        } else {
          toast.error("Your edited timeslots collide with an existing timeslot.")
          setloading(false)
        }
      }).catch((err) => {
        console.log(err, 'U')
        setloading(false)

      })
    }
  }

  const updateslot = async () => {

    if (selectedemployee == "") {

      toast.warn("Please select an employee!")
    } else if (designation == "") {
      toast.warn("Please select a designation!")
    } else if (starttime == undefined) {
      toast.warn("Please select a checkin time!")
    } else if (endtime == undefined) {
      toast.warn("Please select a checkout time!")
    } else if (endtime < starttime) {
      toast.warn("Checkout time must be grater than Checkin time!")
    } else if (Location == "") {
      toast.warn("Please select a work location!")
    }
    else {


      let editallowed = true


      setloading(true)
      let data = {}

      let checkindatetimedata = starttime.split(':');
      const checkindatetime = new Date(slotedit.sheduleddate)
      checkindatetime.setHours(parseInt(checkindatetimedata[0], 10));
      checkindatetime.setMinutes(parseInt(checkindatetimedata[1], 10));

      data["checkindatetime"] = checkindatetime.getTime()
      data["checkintime"] = starttime

      let checkoutdatetimedata = endtime.split(':');
      const checkoutdatetime = new Date(slotedit.sheduleddate)
      checkoutdatetime.setHours(parseInt(checkoutdatetimedata[0], 10));
      checkoutdatetime.setMinutes(parseInt(checkoutdatetimedata[1], 10));

      data["checkoutdatetime"] = checkoutdatetime.getTime()
      data["checkouttime"] = endtime


      let lunchdiff = 0
      if (slotedit?.lunchallowed) {
        let lunchstartimedata = slotedit.lunchstarttime.split(':');
        const lunchstartdate = new Date(slotedit.sheduleddate);
        lunchstartdate.setHours(parseInt(lunchstartimedata[0], 10));
        lunchstartdate.setMinutes(parseInt(lunchstartimedata[1], 10));

        let lunchendtimedata = slotedit.lunchendtime.split(':');
        const lunchenddate = new Date(slotedit.sheduleddate);
        lunchenddate.setHours(parseInt(lunchendtimedata[0], 10));
        lunchenddate.setMinutes(parseInt(lunchendtimedata[1], 10));
        lunchdiff = Math.abs(lunchenddate - lunchstartdate);
      }

      let diff = Math.abs(checkoutdatetime - checkindatetime);
      diff = diff - lunchdiff

      data["minutes"] = diff / 1000 / 60
      data["edited"] = true
      data["editedby"] = user?.id


      await getAllSlotsWherex4("slots", "sheduleddate", "==", slotedit.sheduleddate, "employeeid", "==", slotedit.employeeid, "slotid", "!=", slotedit.slotid, "status", "==", "Accepted").then(async (response) => {



        for (const key in response) {


          let responsecheckindatetimedata = response[key].starttime.split(':');
          const responsecheckindatetime = new Date(slotedit.sheduleddate)
          responsecheckindatetime.setHours(parseInt(responsecheckindatetimedata[0], 10));
          responsecheckindatetime.setMinutes(parseInt(responsecheckindatetimedata[1], 10));


          let responsecheckoutdatetimedata = response[key].endtime.split(':');
          const responsecheckoutdatetime = new Date(slotedit.sheduleddate)
          responsecheckoutdatetime.setHours(parseInt(responsecheckoutdatetimedata[0], 10));
          responsecheckoutdatetime.setMinutes(parseInt(responsecheckoutdatetimedata[1], 10));





          if ((checkindatetime >= responsecheckindatetime && checkindatetime <= responsecheckoutdatetime) || (checkoutdatetime >= responsecheckindatetime && checkoutdatetime <= responsecheckoutdatetime) || (checkindatetime <= responsecheckindatetime && checkoutdatetime >= responsecheckoutdatetime)) {
            editallowed = false

          }



        }


        if (editallowed) {

          await updateItem("slots", slotedit.schedulecreationdate, data).then(async (response) => {

            toast.success("Slot Updated Successfully!")
            setloading(false)
            navigate(-1)
          }).catch((err) => {
            alert(err)
            setloading(false)
            toast.error("Something went wrong while updating your slot. Please try again later.")
          })


        } else {
          toast.error("Your edited timeslots collide with an existing timeslot.")
          setloading(false)
        }

      }).catch((err) => {
        console.log(err)
        setloading(false)

      })

















    }
  }

  // useEffect(()=>{
  // if(selectedemployee.id != undefined){
  //     getallemployeetasks()
  //     getallemployeejobs()
  // }
  // }, [selectedemployee])

  useEffect(() => {
    if(projectid != ""){
      getallprojectdesignations()
    }

  }, [projectid])

  useEffect(() => {
    const handleClickOutside = (event) => {



      if (designationpopupref.current && !designationpopupref.current.contains(event.target)) {
        setdesignationpopup(false);
      }


    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ designationpopupref]);


  const getallprojectdesignations = async () => {
    await getuserinformation("users", projectid).then(async (response) => {

      if (response != undefined) {
        setselectedproject(response)

      }

      if (response.designation != undefined) {
        setdesignationoptions(response.designation)
      }



    }).catch((err) => {
      alert(err)
    })
  }

  const getallemployeetasks = async () => {
    await getAllEmployeeTasks("tasks", "employeeid", "==", selectedemployee.id).then(async (response) => {
      settasks(response)

    }).catch((err) => {
      alert(err)
    })
  }

  const getallemployeejobs = async () => {
    await getAllEmployeeTasks("jobs", "assigned", "==", selectedemployee.id).then(async (response) => {
      setjobs(response)
    }).catch((err) => {
      alert(err)
    })
  }




  return (
    <div className='min-h-screen'>


      <div className='w-[90%]  px-3 lg:px-7 rounded-xl mt-[80px] py-[20px] bg-white  drop-shadow-lg mx-auto  lg:mt-[100px] lg:mb-[30px] '>
        {selectedemployee ?
          <>
            <div className='flex flex-col items-center justify-center'>
              {selectedemployee.profilepicture ?
                <img src={selectedemployee.profilepicture} className='w-[110px] h-[110px] object-cover rounded-full' />
                : <FaUser

                  className="text-lighttextGray/80 border border-gray-300 rounded-full w-[110px] h-[110px]"
                />}

              <p className='PoppinsRegular font-medium text-textcolor mt-[10px]'>{selectedemployee.firstname + " " + selectedemployee.lastname}</p>
              <p className='PoppinsRegular font-medium text-lightgrey text-[12px]'>{selectedemployee.designation}</p>
              {slotedit &&
                <>
                  <p className='PoppinsRegular font-medium text-black text-[12px]'>{slotedit.location?.label}</p>
                  <p className='PoppinsRegular font-medium text-black text-[12px]'>{slotedit.sheduleddate}</p>
                </>
              }


            </div>
            {/* <div className='flex flex-row items-center my-[20px]'>
    <text className='PoppinsRegular font-medium text-textcolor'>Work Schedule:</text>
<text className='ml-[10px] PoppinsRegular font-medium text-textcolor'>{selectedemployee.starttime + " - " + selectedemployee.endtime}</text>

</div> */}
          </>
          : null}
        <div className='flex flex-col justify-between mt-[30px]  w-full '>
          <div className='w-full  md:mt-0'>
            <div

              className='w-full relative' >
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Project</p>
              <div
                  onClick={() => {


                    if (!slotedit) {
                      setprojectpopup(!projectpopup)
                    }

                  }}
                className={`px-4 ${selectedproject == "" ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full  h-[45px] rounded-xl border border-themeColor items-center flex`}>{selectedproject == "" ? "Click to select" : selectedproject.title}</div>


                <ProjectPopUp

style= {{top: "80px", }}
visible ={projectpopup}
data = {clients}

activateoutsidecontainerclick = {()=>{
  setprojectpopup(false);
}}


            onClickcorrect={(item) => {
              setprojectid(item.id)
              setselectedproject(item)
              setdesignation("")
              setselectedemployee("")
              setprojectpopup(false);
            }}
/>
            </div>
          </div>
        </div>
        <div className='flex flex-col  items-center justify-between w-full md:mt-[20px]'>
          <div className='flex flex-col  justify-between   w-full'>

            <div className='w-full  mt-5 md:mt-0'>
              <div


                className='w-full relative' >
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>{slotedit ? "Employee" : "Select Employee"}</p>
                <div
                    onClick={() => {
                      if (!slotedit) {
                        setemployeepopup(!employeepopup)
                      }

                    }}
                  className={`px-4 ${selectedemployee?.firstname == undefined ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full h-[45px] rounded-xl border border-themeColor items-center flex`}>{selectedemployee?.firstname == undefined ? "Click to select" : selectedemployee?.firstname + " " + selectedemployee?.lastname}</div>
                {/* {employeepopup &&
                  <div
                  ref = {employeepopupref}
                  className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
                    {employees.map((item, index) =>
                    (
                      item.usertype != "Supervisor" &&
                      item?.projectassigned?.find(data => data?.project == projectid) != undefined &&
                      <div
                      key = {index.toString()}
                        onClick={() => { setselectedemployee(item) }}
                        className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-[#0989B826] hover:rounded-xl`}>
                        <img src={item.profilepicture} className='w-[25px] h-[25px] rounded-full object-cover' />
                        <p className='text-textcolor font-medium PoppinsRegular text-sm ml-[10px]'>{item.firstname} {item.lastname}</p>
                      </div>
                    ))}
                  </div>} */}
      <EmployeePopup
                  style={{top: "80px"}}
                                visible ={employeepopup}
                                   data = {employees.filter(function (data) { return  data.usertype != "Supervisor" && data?.projectassigned?.find(dataset => dataset.project == projectid) != undefined  })}
                                activateoutsidecontainerclick = {()=>{
                                  setemployeepopup(false);
                                }}

                              onClick={(item, index) => {
                                 setselectedemployee(item)
                                 setemployeepopup(false);
                                }}
                              />


              </div>

            </div>
          </div>


          <div className='flex flex-col justify-between mt-[30px]  w-full '>
            <div className='w-full  md:mt-0 relative'>
              <div

                className='w-full' >
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Designation</p>
                <div
                    onClick={() => {


                      if (!slotedit) {
                        setdesignationpopup(!designationpopup)
                      }

                    }}
                  className={`px-4 ${designation == "" ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full  h-[45px] rounded-xl border border-themeColor items-center flex`}>{designation == "" ? "Click to select" : designation}</div>




                  <DesignationPopUp
                  style={{top: "80px"}}
                                visible ={designationpopup}
                                data = {designationoptions}
                                activateoutsidecontainerclick = {()=>{
                                  setdesignationpopup(false);
                                }}
                                onClick={(item, index) => {
                                  setdesignation(item.designation)
                                  setdesignationpopup(false);
                                 }}

                              />
              </div>
            </div>
          </div>



        </div>
        <div className='flex flex-col  items-center justify-center w-full'>
          <div className='flex flex-col w-full  mt-[30px] '>
            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Date</p>

            <DatePicker
              maxDate={new Date(Date.now())}
              className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={manualdate} onChange={(date) => {
                console.log(date)
                setmanualdate(date)
                // setstartdate(new Date(date.setHours(0, 0, 0, 0)))
              }} />

          </div>

        </div>

        {/* <div className='flex flex-col w-full  mt-[30px] '>
        <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Lunch Break</p>
        <div className='flex flex-row items-center justify-start mt-[5px]'>
<div
onClick={()=>{setlunchenabled(true)}}
className='flex flex-row items-center justify-center hover:cursor-pointer'>
        <p className='PoppinsRegular text-textcolor text-sm font-medium '>Yes</p>
     {lunchenabled ?
        <IoRadioButtonOn

    className=" ml-[5px] text-themeColor border border-gray-300 rounded-full w-[20px] h-[20px]"
    /> :
    <IoRadioButtonOff

    className="ml-[5px] text-white border border-gray-300 rounded-full w-[20px] h-[20px]"
    />}
    </div>
    <div
    onClick={()=>{setlunchenabled(false)}}
    className='flex flex-row items-center justify-center ml-[15px] hover:cursor-pointer'>
        <p className='PoppinsRegular text-textcolor text-sm font-medium '>No</p>
        {lunchenabled ?
         <IoRadioButtonOff

         className="ml-[5px] text-white border border-gray-300 rounded-full w-[20px] h-[20px]"
         />
       :
       <IoRadioButtonOn

       className=" ml-[5px] text-themeColor border border-gray-300 rounded-full w-[20px] h-[20px]"
       />}
</div>
</div>

</div>


{lunchenabled &&
        <div className='flex flex-col  items-center justify-center w-full'>
        <div className='flex flex-col w-full  mt-[20px] '>
        <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Lunch Start Time</p>

  <TimePicker className='rounded-xl border border-themeColor w-full   h-[45px] ' onChange={setlunchstarttime} value={lunchstarttime} />

</div>
<div className='flex flex-col  mt-[30px]  w-full '>
        <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Lunch End Time</p>

  <TimePicker className='  rounded-xl border border-themeColor  w-full h-[45px] ' onChange={setlunchendtime} value={lunchendtime} />

</div>
</div>} */}
        {slotedit ?
          <div className='flex flex-col  items-center justify-center w-full'>
            <div className='flex flex-col w-full  mt-[30px] '>
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Checkin Time</p>

              <TimePicker className='rounded-xl border border-themeColor w-full   h-[45px] ' onChange={setstarttime} value={starttime} />

            </div>
            <div className='flex flex-col  mt-[30px]  w-full '>
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Checkout Time</p>

              <TimePicker className='  rounded-xl border border-themeColor  w-full h-[45px] ' onChange={setendtime} value={endtime} />

            </div>
          </div>
          :
          <div className='flex flex-col  items-center justify-center w-full'>
            <div className='flex flex-col w-full  mt-[30px] '>
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Checkin Time</p>

              <TimePicker className='rounded-xl border border-themeColor w-full   h-[45px] ' onChange={setstarttime} value={starttime} />

            </div>
            <div className='flex flex-col  mt-[30px]  w-full '>
              <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Checkout Time</p>

              <TimePicker className='  rounded-xl border border-themeColor  w-full h-[45px] ' onChange={setendtime} value={endtime} />

            </div>
          </div>
        }
        {/* <div className='w-full mt-[20px] PoppinsRegular' >
        <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Location</p>

        <GooglePlacesAutocomplete



          selectProps={{
            Location,
            onChange: setLocation,
            placeholder: "Select your address",

          }}
          name="address"
          value={Location}
        />


      </div> */}
        {/* <div className='mt-[60px]'>

{slots.map((item, index) =>
                 (
         <div className='flex flex-row justify-start items-center mb-[10px]'>
         <FaCircleMinus
onClick={()=>{
    let data = [...slots]
    data.splice(index, 1)
    setslots(data)
}}
className=" text-red-500 w-[20px] h-[20px] hover:cursor-pointer"
/>
             <div

             className='mx-[10px] w-full' >

             <div
             className={`relative px-4 ${item.project == "" ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full h-[45px] rounded-xl border border-themeColor items-center flex`}>{item.project == "" ? "Select Task/Project" : item?.project}
                   <div className='position absolute z-10 flex flew-row right-[15px]  '>
                <text
                onClick={()=>{

                    let data = [...slots]

                    data[index].tasksvisible =   !data[index].tasksvisible
                    data[index].jobsvisible =   false
                    setslots(data)
                }}
                className='text-sm font-medium PoppinsRegular hover:cursor-pointer hover:text-themeColor'>Tasks</text>
                <text className='text-sm font-medium PoppinsRegular px-[5px]'> | </text>
                <text
                   onClick={()=>{
                    let data = [...slots]
                    data[index].jobsvisible =   !data[index].jobsvisible
                    data[index].tasksvisible =   false
                    setslots(data)
                }}
                className='text-sm font-medium PoppinsRegular hover:cursor-pointer  hover:text-themeColor'>Jobs</text>
             </div>
             </div>

             {item.tasksvisible &&
             <div className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
             {tasks.map((iteminner, indexinner) =>
                 (
                     <div
                     onClick={()=>{
                        let data = [...slots]
                        data[index].project = iteminner.title
                        data[index]["id"] = iteminner.taskcreationdate
                        data[index].tasksvisible = false
                        setslots(data)

                                             }}
                     className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-[#0989B826] hover:rounded-md`}>

                         <text className='text-textcolor font-medium PoppinsRegular text-sm ml-[10px]'>{iteminner.title}</text>
                     </div>
                 ) )}

             </div>}
             {item.jobsvisible &&
             <div className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
             {jobs.map((iteminner, indexinner) =>
                 (
                     <div
                     onClick={()=>{
let data = [...slots]
data[index].project = iteminner.title
data[index]["id"] = iteminner.jobcreationdate
data[index].jobsvisible = false
setslots(data)

                     }}
                     className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-[#0989B826] hover:rounded-md`}>

                         <text className='text-textcolor font-medium PoppinsRegular text-sm ml-[10px]'>{iteminner.title}</text>
                     </div>
                 ) )}

             </div>}
             </div>
             <div
             onClick={()=> {
                let data = [...slots]
                data[index].hoursvisbile =   !data[index].hoursvisbile
                setslots(data)
             }}
             className='w-[20%] md:w-[10%] flex flex-row justify-center items-end' >

             <div
             className={`px-4 text-textcolor text-sm font-medium PoppinsRegular w-full h-[45px] rounded-xl border border-themeColor justify-center items-center flex`}>{item.hours  }</div>
             {item.hoursvisbile &&
             <div className='w-[50px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
             {hours.map((inneritem, innerindex) =>
                 (
                     <div
                     onClick={()=> {
                        let data = [...slots]
                        data[index].hours = inneritem.hours
                        setslots(data)
                     }}
                     className={`flex flex-row items-center justify-center  w-full py-[5px] cursor-pointer hover:bg-[#0989B826] hover:rounded-xl`}>

                         <text className='text-textcolor font-medium PoppinsRegular text-sm'>{inneritem.hours}</text>
                     </div>
                 ) )}
             </div>}
             <text className='text-sm font-medium PoppinsRegular ml-[10px]'>hr's</text>

             </div>




             </div>
                 ))}
</div> */}

        {/* <div
onClick={()=>{
    let object ={
 project: "",
hours: 0,
hoursvisible: false,
tasksvisible: false,
jobsvisible: false
    }

    let data  = [...slots]
    data.push(object)
setslots(data)
}}
className='w-full flex justify-center my-[100px] flex-col items-center hover:cursor-pointer'>
        <CiCirclePlus

className="text-lighttextGray/80 w-[60px] h-[60px]"
/>
<text className='font-semibold PoppinsRegular text-lighttextGray/80'>Add time slot</text>
</div> */}

        <div className='w-full flex items-center justify-center mt-[30px] '>
          {loading ?
            <div
            className=" py-4  mt-6 w-full flex justify-center   "
            >
              <RotatingLines
                visible={true}
                height="25"
                width="25"
                color="grey"
                strokeColor="#218fdb"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>

            :
            <button
              onClick={() => {
                if (slotedit) {
                  updateslot()
                } else {
                  createslot()
                }

              }}
              type='submit' className='text-center py-4 rounded-2xl  bg-themeColor hover:bg-themeColor/80 w-full  text-white text-sm font-semibold PoppinsRegular uppercase '>
              {slotedit ? "Update" : "Create"}
            </button>
          }
        </div>
      </div>
    </div>
  );
}

export default UpdateSlot;