import React, {useRef, useState, useEffect} from 'react'
import Logo from "../images/logo_green_yellow.png"
import './index.css'
import DashboardCenter from '../components/Dasboard-center';
import DashboardRight from '../components/Dashboard-righ';
import DashboardLeft from '../components/Dashbaord-left';
import { DashboardCard, DashboardProfile, DashboardSetting, DashboardSupport, DashboardTrade } from '../components';
import Home from '../images/dashboard/Home.png'
import Profile from '../images/dashboard/Profile.png'
import Setting from '../images/dashboard/Setting.png'
import Card from '../images/dashboard/Card.png'
import Swap from '../images/dashboard/Swap.png'
import { useNavigate, useLocation } from 'react-router-dom'
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useStateValue } from '../context/StateProvider';
import { updateItem} from '../../utils/firebasefunctions';
import { toast } from 'react-toastify';
import Loader from "react-js-loader";
import { FaUser } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebase.config';
import { LuClock } from "react-icons/lu";
import { actionType } from '../context/reducer';

// import Section2 from '../components/Homesection/section2'
function Dashboard() {
  const location = useLocation()
  const fileInput = useRef();
  const [email, setemail] = useState("");
  const [firstname, setfirstname] = useState("");
  const [fullname, setfullname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [designation, setdesignation] = useState("");
  const [laoder, setlaoder] = useState(false);
  const [selectedtime, setselectedtime] = useState(location.state.employeedata.starttime ? location.state.employeedata.starttime :  "12:00");
  const [selectedendtime, setselectedendtime] = useState(location.state.employeedata.endtime ? location.state.employeedata.endtime :  "12:00");

  const [picturedata, setpicturedata] = useState("");
  const [picture, setpicture] = useState("");


  const [{user, employees, clients}, dispatch] = useStateValue();
  useEffect(()=>{
    if(location.state.employeedata.usertype == "Client" || location.state.employeedata.usertype == "Project" ){
      setfullname(location.state.employeedata.name)
    }else{
      setfirstname(location.state.employeedata.firstname)
      setlastname(location.state.employeedata.lastname)
    }
setpicture(location.state.employeedata.profilepicture ? location.state.employeedata.profilepicture : location.state.employeedata.logo ? location.state.employeedata.logo : "")
setphonenumber(location.state.employeedata.phonenumber)
setdesignation(location.state.employeedata?.designation ? location.state.employeedata?.designation : "")

  }, [location.state.employeedata])

  const handleDesignationChange = (event) =>{
    setdesignation(event.target.value)

}



const handlephonenumberChange = (event) =>{
    setphonenumber(event.target.value)

}

const handlefirstnameChange = (event) =>{
    setfirstname(event.target.value)

}
const handlefullnameChange = (event) =>{
  setfullname(event.target.value)

}

const handlelastnameChange = (event) =>{
    setlastname(event.target.value)
}



const updatehandler = async () =>{




  setlaoder(true)

  if(picturedata != ""){
  const imageFile = picturedata.target.files[0]
  const storageRef = ref(storage, `profilepicture/${Date.now()}-${imageFile.name}`)
  const uploadTask = uploadBytesResumable(storageRef, imageFile)
  uploadTask.on('state_changed', (snapshot) => {
      //   const uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
  }, (error) => {
      toast.error("upload error " + error.message);
  }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
        let data = {}

        if(location.state.employeedata.usertype == "Client" || location.state.employeedata.usertype == "Project"){
          data ={

            name: fullname,
            phonenumber: phonenumber,
            profilepicture: downloadURL,
          }
        }else{
          data ={
            firstname: firstname,
            lastname: lastname,
            phonenumber: phonenumber,
            profilepicture: downloadURL,
          }
        }




            await updateItem("users", location.state.employeedata.id, data).then(async(response) => {
              const employeeindex = employees.findIndex(item => item.id === location.state.employeedata.id);
              let dataset = [...employees]
              dataset[employeeindex] = {...dataset[employeeindex], ...data}
              dispatch({
                type: actionType.STORE_EMPLOYEES,
                payload: dataset
            })


         toast.success("Profile Updated!")
              setlaoder(false)

          }).catch((err)=>{

              toast.warn(err)
              setlaoder(false)
          })
      })
          .catch((error) => {
              toast.error(error.message);
          });
  })
  }else{
    let data = {}

    if(location.state.employeedata.usertype == "Client" || location.state.employeedata.usertype == "Project"){
      data ={

        name: fullname,
        phonenumber: phonenumber,


      }
    }else{
      data ={
        firstname: firstname,
        lastname: lastname,
        phonenumber: phonenumber,

      }
    }
        await updateItem("users", location.state.employeedata.id, data).then(async(response) => {
          const employeeindex = employees.findIndex(item => item.id === location.state.employeedata.id);
          let dataset = [...employees]
          dataset[employeeindex] = {...dataset[employeeindex], ...data}
          dispatch({
            type: actionType.STORE_EMPLOYEES,
            payload: dataset
        })

     toast.success("Profile Updated!")
          setlaoder(false)

      }).catch((err)=>{

          toast.warn(err)
          setlaoder(false)
      })
  }




}


const handleimageChange = (event) => {
  fileInput.current.click();
}


const handleimageselected = (e) => {
  setpicturedata(e)
  setpicture(URL.createObjectURL(e.target.files[0]));
}

  return (
    <div className=' bg-background w-full mt-[90px] px-[20px]'>

   <div className='flex flex-col items-center w-full '>


<div className='flex flex-col  w-full bg-themeColor drop-shadow-lg rounded-lg py-[40px]'>
  <div className=' w-full flex flex-col  justify-center  items-center'>
                            <div
                                className='relative flex   justify-center my-[10px] border border-white rounded-full items-center mt-5 lg:mt-0'

                            >
                                {picture ? <img src={picture} className='w-[150px] h-[150px] object-contain rounded-full' /> : <FaUser

                                    className="text-lighttextGray/80 p-4 border border-gray-300 rounded-full w-[110px] h-[110px]"
                                />}
                                <input type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleimageselected} />

                                <div onClick={handleimageChange} className='absolute right-[-6px] top-[95px] bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer'>
                                    <MdEdit className="text-textcolor w-[20px] h-[20px]" />
                                </div>

                            </div>
                            <div className='flex flex-col ml-[10px] items-center '>
                                <p className='text-white PoppinsRegular text-base lg:text-base font-bold'>
          {location.state.employeedata.usertype == "Client" ? location.state.employeedata.name : location.state.employeedata.usertype == "Project" ?  location.state.employeedata.title == "Project" : firstname + " " + lastname}
        </p>
        <p className=' text-lightgrey PoppinsRegular font-medium text-base lg:text-sm'>{location.state.employeedata?.designation}</p>
        </div>
                        </div>

</div>

<div className='w-[100%]  mx-auto  px-3 lg:px-7 rounded-xl mt-5 bg-white drop-shadow-lg py-[40px]'>
  <p className='text-lg font-bold text-textcolor PoppinsRegular '>Employee Details</p>
  <div className='flex flex-col md:flex-row justify-between mt-[30px]'>
    <div className='w-full  md:w-[49%] flex items-center'>
      <div className='w-10 h-10  bg-themeColor rounded-full flex justify-center items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-6 h-6  stroke-white">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
        </svg>
      </div>

      <div className='pl-2'>
        <p className='text-textcolor PoppinsRegular text-lg lg:text-base font-medium'>Name</p>
        <p className='text-textcolor/80 PoppinsRegular text-base lg:text-sm font-medium'>
          {location.state.employeedata.usertype == "Client" ? location.state.employeedata.name : location.state.employeedata.usertype == "Project" ?  location.state.employeedata.title == "Project" : firstname + " " + lastname}
        </p>
      </div>
    </div>
    <div className='w-full md:w-[49%] flex items-center mt-3 md:mt-0  '>
      <div className='w-10 h-10 bg-themeColor  rounded-full flex justify-center items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-6 h-6 stroke-white">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
        </svg>
      </div>
      <div className='pl-2'>
        <p className='text-textcolor PoppinsRegular text-lg lg:text-base font-medium'>Email</p>
        <p className='text-textcolor/80 PoppinsRegular font-medium text-base lg:text-sm'>{location.state.employeedata.email}</p>
      </div>
    </div>
  </div>
  <div className='flex flex-col md:flex-row justify-between mt-3 md:mt-[30px]'>
    <div className='w-full md:w-[49%] flex items-center '>
      <div className='w-10 h-10 bg-themeColor  rounded-full flex justify-center items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-6 h-6 stroke-white">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
        </svg>
      </div>
      <div className='pl-2'>
        <p className='text-textcolor PoppinsRegular text-lg lg:text-base font-medium'>Phone Number</p>
        <p className='text-textcolor/80 PoppinsRegular text-base lg:text-sm font-medium'>
          {phonenumber}
        </p>
      </div>
    </div>
    {location.state.employeedata.usertype != "Client" && location.state.employeedata.usertype != "Admin"  && location.state.employeedata.usertype !=  "SuperAdmin" ?
    <div className='w-full md:w-[49%] flex items-center mt-3 md:mt-0  '>
      <div className='w-10 h-10 bg-themeColor  rounded-full flex justify-center items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-6 h-6 stroke-white">
          <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
        </svg>
      </div>
      {location.state.employeedata?.designation &&
      <div className='pl-2'>
        <p className='text-textcolor PoppinsRegular text-lg lg:text-base font-medium'>Designation</p>
        <p className='text-textcolor/80 PoppinsRegular font-medium text-base lg:text-sm'>{location.state.employeedata?.designation}</p>
      </div>
}
    </div> : null
}
  </div>
</div>

</div>
<div className='w-[100%] px-3 lg:px-7 rounded-xl py-6  mx-auto my-5 bg-white drop-shadow-lg'>
<div className='flex flex-col md:flex-row justify-between'>
{location.state.employeedata.usertype == "Client" ?
     <div className='w-full md:w-[49%]' >
     <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>{location.state.employeedata.usertype == "Client" ? "Full Name" :  location.state.employeedata.usertype == "Project" ? "Project Title" : "First name"}</p>
     <input type='text'
     value={fullname} placeholder='First name'
     onChange={handlefullnameChange}
     className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
 </div>


:
<>
                        <div className='w-full md:w-[49%]' >
                            <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>First name</p>
                            <input type='text'
                            value={firstname} placeholder='First name'
                            onChange={handlefirstnameChange}
                            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
                        </div>


                        <div className='w-full md:w-[49%] mt-[10px] md:mt-[0px] ' >
                            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Last name</p>
                            <input type='text'
                            value={lastname} placeholder='Last name'
                            onChange={handlelastnameChange}
                            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
                        </div>
                        </>}
                        </div>


                        <div className='flex flex-col md:flex-row justify-between'>
                        {location.state.employeedata.usertype == "Employee" &&
                        <div className='w-full md:w-[49%] mt-[10px] ' >
                            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Designation</p>
                            <input type='text'
                            disabled ={location.state.employeedata.usertype == "Admin"  || location.state.employeedata.usertype == "SuperAdmin" ? false : true}
                            value={designation} placeholder='Designation'
                            onChange={handleDesignationChange}
                            className={`${location.state.employeedata.usertype == "Admin" || location.state.employeedata.usertype == "SuperAdmin" ? null : "bg-lightgrey"}  px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex`}/>
                        </div>
}
                        <div className='w-full md:w-[49%]  mt-[10px] ' >
                            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Phone Number</p>
                            <input
                                value={phonenumber} placeholder='Phone Number'
                                onChange={handlephonenumberChange}
                            type="tel" className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
                        </div>
                        </div>

                        <div className='flex w-full items-center justify-center pt-[20px]'>
  {laoder ?
    <Loader type="spinner-cub" bgColor={"#218fdb"}  size={30} />:
<button type='submit' className="text-center py-4 rounded-2xl mt-[10px] bg-themeColor hover:bg-themeColor/80 w-[100%]  mx-auto text-white text-sm lg:text-base font-semibold PoppinsRegular uppercase" onClick={()=>updatehandler()}>Update</button>
}
</div>

</div>

    </div>
  );
}

export default Dashboard;