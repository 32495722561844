import React, { useEffect, useRef, useState } from 'react'
import './index.css'

import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { saveuserdata } from '../../utils/firebasefunctions';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import {
    getDownloadURL,
    ref,
    uploadBytes,
    uploadBytesResumable
} from "firebase/storage";
import { storage, app } from '../../firebase.config'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { RotatingLines } from 'react-loader-spinner'

const CreateEmployee = (props) => {
    const [{ user }, dispatch] = useStateValue();
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [picturedata, setpicturedata] = useState("");
    const [picture, setpicture] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [phonenumber, setphonenumber] = useState("");
    const [usertype, setusertype] = useState("Employee")
    const [designation, setdesignation] = useState("General")
    const [designationtypepopup, setdesignationtypepopup] = useState(false)
    const [usertypepopup, setusertypepopup] = useState(false)
    const [passwordvisible, setpasswordvisible] = useState(false)
    const [loader, setloader] = useState(false)
    const [confirmpasswordvisible, setconfirmpasswordvisible] = useState(false)
    const [usertypelist, setusertypelist] = useState([]);

const usertypepopupref = useRef(null)
const designationtypepopupref = useRef(null)

    useEffect(() => {
        if (user?.id) {

            initialiseusertypes()
        }
    }, [user])

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (usertypepopupref.current && !usertypepopupref.current.contains(event.target)) {
            setusertypepopup(false);
          }

          if (designationtypepopupref.current && !designationtypepopupref.current.contains(event.target)) {
            setdesignationtypepopup(false);
          }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [usertypepopupref, designationtypepopupref]);




    const [designationtype, setdesignationtype] = useState([
        {
            name: "General"
        },
        {
            name: "Driver"
        },
    ]);


    const fileInput = useRef();
    const auth = getAuth();

    const signuphandler = async () => {
        // if (picture == "") {
        //     toast.warn("Please select a profile picture")
        //     return
        // } else
        if (firstname == "") {
            toast.warn("Please enter your first name")
            return
        } else if (lastname == "") {
            toast.warn("Please enter your last name")
            return
        } else if (email == "") {
            toast.warn("Please enter your email address")
            return
        } else if (phonenumber == "") {
            toast.warn("Please enter your phone number")
            return
        }
        // } else if (password == "") {
        //     toast.warn("Please enter your password")
        //     return
        // } else if (confirmpassword == "") {
        //     toast.warn("Please enter your password")
        //     return
        // } else if (password != confirmpassword) {
        //     toast.warn("Passwords do not match")
        //     return
        // }

        else {
            setloader(true)
            const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
            let password = '';

            for (let i = 0; i < 8; i++) {
              const randomIndex = Math.floor(Math.random() * charset.length);
              password += charset[randomIndex];
            }

            createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    // Signed up
                    const createduser = userCredential.user;

                    if (picture == "") {



                        let data = {
                            id: createduser.uid,
                            firstname: firstname,
                            createdby: user?.id,
                            lastname: lastname,
                            email: email,
                            phonenumber: phonenumber,
                            profilepicture: "https://firebasestorage.googleapis.com/v0/b/raftekpro-82bb0.appspot.com/o/profilepicture%2F1726660552437-image_2024_09_18T11_54_21_012Z.png?alt=media&token=528ec161-9338-414d-9c14-b8078ae263fc",
                            designation: usertype == "Supervisor" ? "Supervisor" : usertype != "Admin" && usertype != "Supervisor" ? designation : "",
                            usertype: usertype,
                            leaves: 0

                        }
if( usertype == "Admin" || usertype == "SuperAdmin" ){
    data["deleteaccess"] = true
    data["editaccess"] = true
}else if(usertype == "Supervisor"){
    data["deleteaccess"] = false
    data["editaccess"] = false
    data["employeerole"] = false
}

                        await saveuserdata(data, createduser.uid).then((response) => {
                            if (response == "success") {
                                toast.success("Sign Up Successful")



                                setpicture("")
                                setfirstname("")
                                setlastname("")
                                setemail("")
                                setphonenumber("")
                                setpassword("")
                                setconfirmpassword("")
                            } else {
                                toast.error(response)
                            }
                            setloader(false)
                        })


                    } else {


                        //////////////////////////////////////////
                        const imageFile = picturedata.target.files[0]
                        const storageRef = ref(storage, `profilepicture/${Date.now()}-${imageFile.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, imageFile)
                        uploadTask.on('state_changed', (snapshot) => {
                            //   const uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
                        }, (error) => {
                            setloader(false)
                            toast.error("upload error " + error.message);
                        }, () => {
                            getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
                                let data = {
                                    id: createduser.uid,
                                    createdby: user?.id,
                                    firstname: firstname,
                                    lastname: lastname,
                                    email: email,
                                    phonenumber: phonenumber,
                                    profilepicture: downloadURL,
                                    designation: usertype == "Supervisor" ? "Supervisor" : usertype != "Admin" && usertype != "Supervisor" ? designation : "",
                                    usertype: usertype,
                                    leaves: 0

                                }
                                await saveuserdata(data, createduser.uid).then((response) => {
                                    if (response == "success") {
                                        toast.success("Sign Up Successful")



                                        setpicture("")
                                        setfirstname("")
                                        setlastname("")
                                        setemail("")
                                        setphonenumber("")
                                        setpassword("")
                                        setconfirmpassword("")
                                    } else {
                                        toast.error(response)
                                    }
                                    setloader(false)
                                })
                            })
                                .catch((error) => {
                                    setloader(false)
                                    toast.error(error.message);
                                });
                        })
                    }


                    const templateParams = {
                        email: email,
                        message: `email: ${email} password: ${password}`,
                      };

                      emailjs.send(
                        'service_5z77uee',      // Replace with your Service ID
                        'template_qncqtt5',     // Replace with your Template ID
                        templateParams,
                        'pUoX1kN2nzw7N8UsM'          // Replace with your User ID
                      )
                      .then((response) => {
                        console.log('SUCCESS!', response.status, response.text);
                      })
                      .catch((err) => {
                        console.error('FAILED...', err);
                      });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    toast.error(errorMessage)
                    setloader(false)
                    // ..
                });
        }
    }

    const initialiseusertypes = () => {
        if (user?.usertype == "SuperAdmin") {
            setusertypelist([
                {
                    name: "Employee"
                },
                {
                    name: "Admin"
                },
                {
                    name: "Supervisor"
                }

            ])
        } else {
            setusertypelist([
                {
                    name: "Employee"
                },

                {
                    name: "Supervisor"
                }

            ])
        }
    }


    const handleemailChange = (event) => {
        setemail(event.target.value)

    }

    const handlepasswordChange = (event) => {
        setpassword(event.target.value)

    }
    const handleconfirmpasswordChange = (event) => {
        setconfirmpassword(event.target.value)

    }

    const handlephonenumberChange = (event) => {
        setphonenumber(event.target.value)

    }

    const handlefirstnameChange = (event) => {
        setfirstname(event.target.value)

    }

    const handlelastnameChange = (event) => {
        setlastname(event.target.value)
    }


    const handleimageChange = (event) => {
        fileInput.current.click();
    }


    const handleimageselected = (e) => {
        setpicturedata(e)
        setpicture(URL.createObjectURL(e.target.files[0]));
    }


    return (
        <div className='w-[100%] lg:flex  items-center  flex-col  mt-[70px] md:mt-[0px] '>
            <p className='text-[22px] text-textcolor PoppinsRegular font-semibold self-start '>User Registration</p>
            <div className='w-full  flex items-center justify-center mt-[20px]'>
                <div className='w-[100%] lg:w-[100%] mx-auto '>
                    <div className='bg-white py-3 px-7 border border-gray-300 rounded-xl bg-cardOverlay  drop-shadow-lg'>
                        <div className='flex flex-col lg:flex-row justify-start lg:justify-between pt-3 lg:items-center'>

                            <div className='flex flex-row items-center mt-5 lg:mt-0'>
                                <div className='relative' onClick={() => {
                                    setusertypepopup(!usertypepopup)
                                    setdesignationtypepopup(false)
                                }}>
                                    <p className='text-textcolor PoppinsRegular font-medium cursor-pointer'>{usertype == "Admin" ? "Client" : usertype}</p>
                                    {usertypepopup &&
                                        <div
                                        ref = {usertypepopupref}
                                        className='w-[150px] bg-white rounded-xl border border-gray-300 flex items-center flex-col p-[5px] absolute z-10 right-[-60px] lg:left-[0px] top-[30px]'>
                                            {usertypelist.map((item, index) => (

                                                <div
                                                key = {index.toString()}
                                                onClick={() => {
                                                    setusertype(item.name)
                                                    setusertypepopup(!usertypepopup)
                                                }}
                                                    className='hover:bg-themeColor hover:rounded-xl w-full text-center py-2 cursor-pointer text-textcolor hover:text-white'>
                                                    <p className='PoppinsRegular font-medium'>{item.name == "Admin" ? "Client" : item.name}</p>
                                                </div>
                                            ))}
                                        </div>}
                                </div>
                                {usertype != "Admin" && usertype != "Supervisor" &&
                                    <div
                                        className='ml-[10px] relative'
                                        onClick={() => {
                                            setusertypepopup(false)
                                            setdesignationtypepopup(!designationtypepopup)
                                        }}>
                                        <p className='text-textcolor PoppinsRegular font-medium cursor-pointer'>{designation}</p>
                                        {designationtypepopup &&
                                            <div
                                            ref ={designationtypepopupref}
                                            className='flex  bg-white rounded-md flex-col z-10 left-[0px] top-[30px] border border-gray-300 p-[5px] absolute'>
                                                {designationtype.map((item, index) => (
                                                    <div
                                                    key = {index.toString()}
                                                    onClick={() => {
                                                        setdesignation(item.name)
                                                        setdesignationtypepopup(!designationtypepopup)
                                                    }}
                                                        className='hover:bg-themeColor hover:rounded-xl w-full text-center py-2 cursor-pointer text-textcolor hover:text-white px-[50px]'>
                                                        <p className=' font-medium PoppinsRegular text-sm '>{item.name}</p>
                                                    </div>
                                                ))}
                                            </div>}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='w-full flex justify-center'>
                            <div
                                className='flex justify-center my-[10px] border border-white rounded-full items-center relative mt-5 lg:mt-0'

                            >
                                {picture ? <img src={picture} className='w-[110px] h-[110px] object-cover rounded-full bg-cardOverlay  drop-shadow-lg' /> : <FaUser

                                    className="text-lighttextGray/80 p-4 border border-gray-300 rounded-full w-[110px] h-[110px] bg-cardOverlay  drop-shadow-lg bg-white"
                                />}
                                <input type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleimageselected} />
                                <div
                                    onClick={handleimageChange}

                                    className='absolute bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full right-[-10px] top-[70px] cursor-pointer'>
                                    <MdEdit

                                        className="text-textcolor w-[20px] h-[20px]"
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>First name</p>
                                <input type='text'
                                    value={firstname} placeholder='First name'
                                    onChange={handlefirstnameChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>


                            <div className='w-full lg:w-[48%] mt-4 lg:mt-0' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Last name</p>
                                <input type='text'
                                    value={lastname} placeholder='Last name'
                                    onChange={handlelastnameChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                        </div>


                        <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Email</p>
                                <input type='text'
                                    value={email} placeholder='Email'
                                    onChange={handleemailChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                            <div className='w-full lg:w-[48%] mt-4 lg:mt-0' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Phone Number</p>
                                <input
                                    value={phonenumber} placeholder='Phone Number'
                                    onChange={handlephonenumberChange}
                                    type="tel" className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                        </div>

                        {/* <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full relative lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Password</p>
                                <input
                                    value={password} placeholder='Enter password'
                                    onChange={handlepasswordChange}
                                    type={passwordvisible ? "text" : "password"} className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                                <div
                                                                    onClick={()=>{setpasswordvisible(!passwordvisible)}}
                                className='absolute top-[37px] right-[10px]'>
                                {passwordvisible ?
                                <IoMdEye

className="text-themeColor w-[25px] h-[25px]"
/>
:
<IoMdEyeOff

className="text-themeColor w-[25px] h-[25px]"
/>

    }

                                </div>
                            </div>

                            <div className='w-full relative lg:w-[48%] mt-4 lg:mt-0'>
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Confirm password</p>
                                <input
                                    value={confirmpassword} placeholder='Confirm password'
                                    onChange={handleconfirmpasswordChange}
                                    type={confirmpasswordvisible ? "text" : "password"}  className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                                    <div
                                    onClick={()=>{setconfirmpasswordvisible(!confirmpasswordvisible)}}
                                    className='absolute top-[37px] right-[10px]'>
                                    {confirmpasswordvisible ?
                                <IoMdEye

className="text-themeColor w-[25px] h-[25px]"
/>
:
<IoMdEyeOff

className="text-themeColor w-[25px] h-[25px]"
/>

    }

                                </div>
                            </div>
                        </div> */}

                        <div className='w-full lg:w-[100%] mx-auto'>
                            {loader ?
                                <div
                                className="text-center py-4  mt-6 w-full flex justify-center"
                                >
                                    <RotatingLines
                                        visible={true}
                                        height="25"
                                        width="25"
                                        strokeColor="#218fdb"

                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        ariaLabel="rotating-lines-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                                :
                                <button type='submit' className="text-center py-4 rounded-2xl mt-6 bg-themeColor hover:bg-themeColor/80 w-full text-white text-sm lg:text-base font-semibold PoppinsRegular uppercase" onClick={() => signuphandler()}>Create</button>
                            }
                        </div>


                        <div className="w-full h-[1px] mt-6 bg-bordercontainer"></div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateEmployee;