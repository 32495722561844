import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { getAllUserItems, getAllEmployeeTasks, saveitemwithkey, getlivedata, getAllChats, getAdminChats, updateItem } from '../../utils/firebasefunctions';
import { arrayUnion, collection, doc, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import { EmployeeHome, AdminHome } from '../components'
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
import { IoCamera } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { firestored, storage, app } from "../../firebase.config";
import userlogo from '../images/user.png'
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable
} from "firebase/storage";
import { HiArrowSmallLeft } from "react-icons/hi2";
// import Section2 from '../components/Homesection/section2'
function Messages() {

  const [{ user, tasks, employees }, dispatch] = useStateValue();
  const [message, setmessage] = useState("");
  const [messagearray, setmessagearray] = useState([]);
  const [chatid, setchatid] = useState("");
  const [chats, setchats] = useState([]);
  const [picturedata, setpicturedata] = useState("");
  const [picture, setpicture] = useState("");
  const [loader, setloader] = useState(false);
  const [chatterdata, setchatterdata] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const endOfMessagesRef = useRef(null);
  const endOfMessagesRefmobile = useRef(null);



  const fileInput = useRef();


  useEffect(() => {
    if (user?.id) {
      if (user?.usertype != "Admin" && user?.usertype != "SuperAdmin") {
        checkconversationexistance()
      }

      if (user?.usertype == "SuperAdmin") {
        getchatdataSuperAdmin()
      } else if (user?.usertype == "Admin") {
        getchatdataAdmin()
      }
    }

  }, [])



  useEffect(() => {
    if (chatid != "" && chatid != undefined) {

      const subscriber = onSnapshot(doc(firestored, "conversations", chatid), (doc) => {
        if (doc.data()?.messages?.length > 0) {

          setmessagearray(doc.data().messages)
        } else {
          setmessagearray([])
        }

      });



      // Cleanup subscription on unmount
      return () => subscriber();
    }


  }, [chatid])

  useEffect(() => {
    // Scroll to the bottom of the chat when new messages arrive
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    endOfMessagesRefmobile.current?.scrollIntoView({ behavior: 'smooth' });

  }, [messagearray]);


  const getchatdataSuperAdmin = async () => {
    onSnapshot(query(collection(firestored, 'conversations'), orderBy("latestMessageTimeMs", "desc")), (querySnapshot) => {
      let data = []

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });



      for (const index in data) {
        let employeedata = employees.filter(function (item) {
          return item.id == data[index].owners[0];
        }).map(function (item) {
          return item;
        });

        data[index]["employeedata"] = employeedata
      }



      data = data.filter(function (item) {
        return item?.messages?.length > 0
      })
      setchats(data)


      // setchatid(data[0]?.id)
      // setchatterdata(data[0]?.employeedata[0])





    })


    ////////NON SoCKET//////////////////////////////
    //     await getAllChats('conversations')
    //     .then(async response => {
    //         let data = [...response]
    //         for (const index in data){
    // let employeedata = employees.filter(function(item){
    //     return item.id == data[index].owners[0];
    //  }).map(function(item){
    //      return item;
    //  });

    //  data[index]["employeedata"] = employeedata
    //         }

    //         setchats(data)
    //         setchatid(data[0]?.id)
    //         setchatterdata(data[0]?.employeedata[0])
    //     })
    //     .catch(error => {
    //       alert(error)
    //     });
    /////////////////////////////////////////////////
  }
  const getchatdataAdmin = async () => {


    onSnapshot(query(collection(firestored, 'conversations'), where("admin", "==", user?.id), orderBy("latestMessageTimeMs", "desc")), (querySnapshot) => {
      let data = []

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });



      for (const index in data) {
        let employeedata = employees.filter(function (item) {
          return item.id == data[index].owners[0];
        }).map(function (item) {
          return item;
        });

        data[index]["employeedata"] = employeedata
      }

      data = data.filter(function (item) {
        return item?.messages?.length > 0
      })
      setchats(data)


      // setchatid(data[0]?.id)
      // setchatterdata(data[0]?.employeedata[0])





    })


    ////////NON SoCKET//////////////////////////////
    //     await getAdminChats('conversations', user.id)
    //     .then(async response => {

    //         let data = [...response]

    //         for (const index in data){
    // let employeedata = employees.filter(function(item){
    //     return item.id == data[index].owners[0];
    //  }).map(function(item){
    //      return item;
    //  });

    //  data[index]["employeedata"] = employeedata
    //         }

    //         setchats(data)
    //         setchatid(data[0]?.id)
    //         setchatterdata(data[0]?.employeedata[0])
    //     })
    //     .catch(error => {
    //       alert(error)
    //     });
    /////////////////////////////////////////////////////
  }

  const handleimageChange = (event) => {
    fileInput.current.click();
  }


  const handleimageselected = (e) => {

    setpicturedata(e)
    setpicture(URL.createObjectURL(e.target.files[0]));
  }

  const handlemessageChange = (event) => {
    setmessage(event.target.value)
  }



  async function checkconversationexistance() {
    await getAllEmployeeTasks("conversations", "owners", "array-contains", user?.id).then(async (response) => {
      if (response.length > 0) {
        response.forEach(doc => {
          setchatid(doc.id);
        });
      } else {
        const nowMs = Date.now();
        let chatkey = "conversation_" + nowMs + "_" + Math.floor(Math.random() * 1000);
        let EditedTimestamp = moment(new Date()).format('YYYY-MM-DD hh:mm:ss a');
        let owners = []
        owners.push(user?.id)

        let chatdata = {
          createdTimestamp: EditedTimestamp,
          id: chatkey,
          latestMessageTimeMs: EditedTimestamp,
          owners: owners,
        }
        await saveitemwithkey('conversations', chatkey, chatdata)
          .then(async response => {
            if (response == "success") {
              setchatid(chatkey);
            } else {
              alert("Something went wrong")
            }
          })
          .catch(error => {
            alert(error)
          });
      }
    }).catch((err) => {
      alert(err)
    })




  }



  const sendmessage = async () => {

    console.log('checking availability');


    if (message == '' && picturedata.length < 1) {
      toast.warn("You can not send a blank message")
    } else {
      setloader(true);
      const nowMs = Date.now();
      const messageId = "messageText_" + nowMs + "_" + Math.floor(Math.random() * 100000);
      let EditedTimestamp = moment(new Date()).format('YYYY-MM-DD hh:mm:ss a');
      let messagedata = {}
      let imagelink = ""
      if (picturedata != "") {


        const imageFile = picturedata.target.files[0]
        const storageRef = ref(storage, `profilepicture/${Date.now()}-${imageFile.name}`)
        const uploadTask = uploadBytesResumable(storageRef, imageFile)
        uploadTask.on('state_changed', (snapshot) => {
          //   const uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
        }, (error) => {
          toast.error("upload error " + error.message);
        }, async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
            imagelink = downloadURL

            messagedata = {

              id: messageId,
              createdMs: nowMs,
              createdTimestamp: EditedTimestamp,
              owner: user?.id,
              text: message,
              hasImages: true,
              hasText: false,
              image: imagelink
            };

            let displaymessage = {

              latestMessageTimeMs: nowMs,
              latestmessage: message,
              messages: arrayUnion(messagedata),
              latestmessageseen: false

            }

            await saveitemwithkey('conversations', chatid, displaymessage)
              .then(async response => {
                if (response == "success") {
                  console.log("edited time updated")
                  setmessage('');
                  setpicturedata("")
                  setpicture("")
                } else {
                  alert("Something went wrong")
                }
                setloader(false)
              })
              .catch(error => {
                alert(error)
                setloader(false)
              });
          })
        })

      } else {

        messagedata = {
          id: messageId,
          createdMs: nowMs,
          createdTimestamp: EditedTimestamp,
          owner: user?.id,
          text: message,
          hasImages: false,
          hasText: true,

        };

        let displaymessage = {

          latestMessageTimeMs: nowMs,
          latestmessage: message,
          messages: arrayUnion(messagedata),
          latestmessageseen: false

        }

        await saveitemwithkey('conversations', chatid, displaymessage)
          .then(async response => {
            if (response == "success") {
              console.log("edited time updated")
              newmessagesuccess(user.title ? user.title : user.firstname + " " + user.lastname, message, chatterdata.id)
              setmessage('');
              setpicturedata("")
              setpicture("")

            } else {
              alert("Something went wrong")
            }
            setloader(false)
          })
          .catch(error => {
            alert(error)
            setloader(false)
          });
      }







    }
  }

  const newmessagesuccess = async (sender, message, id) => {
    try {

      // const res = await fetch('https://us-central1-raftekpro-82bb0.cloudfunctions.net/api/newslot', {
        const res = await fetch('https://us-central1-raftekpro-82bb0.cloudfunctions.net/api/newmessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: sender,
           message: message,
            id: id
        })
      });
if(res.status == 200){
console.log('new message sent ' + JSON.stringify(res))
const data = await res.json();
console.log(data)
 console.log('Successfully Notified User')
}else{

console.log('Notififcation Error' + JSON.stringify(res))
}

    } catch (error) {
      console.error('Error:', error);

    }
  };

  const handlesearchChange = (event) => {
    setsearchtext(event.target.value)

  }
  return (

    <div className='mt-20'>
      <div className='w-full hidden md:flex flex-row h-[90vh] rounded-lg border border-gray-300'>
        {user?.usertype == "Admin" || user?.usertype == "SuperAdmin" ?
          <div className=' bg-themeColor w-[25%] h-full'>
            <div className='bg-themeColor relative flex justify-center py-5 h-[12%] px-4 w-full'>
              <div className='h-[100%] relative flex items-center w-full'>
              <input type='text' placeholder='Search'
                value={searchtext}
                onChange={handlesearchChange}

                className="pl-10 text-textcolor text-sm font-medium rounded-full PoppinsRegular w-[100%] py-2 border border-themeColor items-center flex" />
                    <div className='absolute left-[10px] '>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-5 h-5 stroke-textcolor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
              </div>

                </div>

            </div>
            <div className='  overflow-y-scroll h-[88%] w-full'>
            {chats.map((item, index) => (

              item.employeedata[0]?.firstname.toLowerCase().includes(searchtext.toLowerCase()) || item.employeedata[0]?.lastname.toLowerCase().includes(searchtext.toLowerCase()) ?
                <div
                key = {index.toString()}
                  onClick={() => {
                    updateItem('conversations', item.id, { latestmessageseen: true })
                    setchatid(item.id)
                    setchatterdata(item.employeedata[0])
                  }}
                  className='w-full border-b-2 border-gray-300 hover:bg-themeColor/10 flex flex-row items-center py-[10px] px-4 cursor-pointer justify-between '>

                  <div className='w-[15%] flex items-center justify-center h-[70px]'>
                    <img src={item.employeedata[0]?.profilepicture} className='w-[35px] h-[35px] object-cover rounded-full' />
                  </div>
                  <div className='w-[60%] flex flex-col justify-center overflow-hidden h-[70px] px-[10px]'>
                    <p className='text-white PoppinsRegular font-bold text-base line-clamp-1'>{item.employeedata[0]?.firstname + " " + item.employeedata[0]?.lastname}</p>
                    <div className='flex flex-row w-[100%]'>
                      <p className=' text-lighttextGray PoppinsRegular font-semibold text-xs w-[100%]'>{item.latestmessage}</p>
                    </div>
                  </div>
                  <div className='w-[25%] flex flex-col items-center h-[55px] lg:h-[50px]'
                  >
                    <p className='text-lighttextGray PoppinsRegular text-[10px] h-[30px] lg:h-[27.5px] text-center'>{moment(item.latestMessageTimeMs).fromNow()}</p>
                    {item.latestmessageseen == false ?
                      <div style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: 'red',
                        marginTop: 4
                      }}>
                      </div>
                      : null
                    }
                  </div>

                </div>
                : null
            ))}
            </div>
          </div> : null
        }
        {chatid != "" && chatid != undefined &&
          <div className='relative flex flex-col  bg-white  w-[75%]'>
            <div className='px-8 py-4 flex items-center'>
              <div className='w-[80%]'>
                {chatterdata?.firstname != undefined &&
                  <>
                    <h1 className='PoppinsRegular text-textcolor font-bold text-2xl'>{chatterdata.firstname + " " + chatterdata.lastname}</h1>
                    <p className='PoppinsRegular text-textcolor/50 font-medium text-sm'>{chatterdata.designation}</p>
                  </>}
              </div>
              <div className='w-[20%] flex justify-end items-center'>
                <div className='w-10 h-10 cursor-pointer bg-[#e0e0e0] rounded-full flex justify-center items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-6 h-6 stroke-black">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>
                </div>
                <div className='w-10 h-10 ml-5 bg-[#e0e0e0] cursor-pointer rounded-full flex justify-center items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-6 h-6 stroke-black">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className='w-[95%] mx-auto h-[1px] bg-gray-300'></div>
            <div className='w-[100%] pb-24 overflow-y-scroll gk-scr px-6'>
              {messagearray.map((item, index) => (
                <div
                key = {index.toString()}
                className={` my-4 flex flex-col ${item.owner == user?.id ? "items-end" : "items-start"}`}>
                  <div className='flex max-w-[85%]'>
                    {/* <img src={userlogo} className='w-[35px] h-[35px] object-cover mt-1' /> */}
                    <div className={`pl-4 flex flex-col ${item.owner == user?.id ? "items-end" : "items-start"}`}>
                      <div className={` ${item?.hasImages ? null : item.owner == user?.id ? "bg-stone-100 " : "bg-themeColor text-white "} ${item?.hasImages ? null : "px-5"} py-2 rounded-full`}>
                        {item?.hasImages
                          ?
                          <img src={item.image} className='w-[200px] h-[100px] object-cover' />
                          :
                          <p ref={endOfMessagesRef} className='text-sm PoppinsRegular font-medium'>{item.text}</p>
                       }
                      </div>
                      <p className={` PoppinsRegular font-medium pt-1 text-[10px] ${item.owner == user?.id ? "mr-[10px]" : "ml-[10px]"}`}>{moment(item.createdMs).fromNow()}</p>
                    </div>

                  </div>
                </div>
              ))}
            </div>
            <div className='w-full bg-white flex justify-center py-[20px] items-center position absolute bottom-0 px-[60px] z-50'>
              {picturedata ?
                <div lass=" px-4 text-whitecolor text-base w-full py-2 bg-textinputbackground rounded-2xl border border-bordercontainer items-center flex">
                  <div className='relative w-[200px] h-[100px] '>
                    <IoIosCloseCircle
                      onClick={() => {
                        setpicture("")
                        setpicturedata("")
                      }}
                      className={`text-red-600 text-[20px] absolute -top-[5px] -right-[5px] z-10`}
                    />
                    <img src={picture} className='w-[100%] h-[100%] object-cover' />
                  </div>
                </div>
                :
                <>
                  <input type='text'
                    value={message}
                    placeholder='Type message'
                    onChange={handlemessageChange}
                    className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex" />
                  {/* <input type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleimageselected} /> */}

                  {/* <div
                    onClick={handleimageChange}
                    className='flex items-center justify-center w-[50px] h-[40px] cursor-pointer rounded-full bg-themeColor hover:bg-themeColor/80 ml-[10px]'>
                    <IoCamera
                      className={`text-white text[45px]`}
                    />
                  </div> */}
                </>}
              <div
                onClick={() => {
                  sendmessage()
                }}
                className='bg-themeColor hover:bg-themeColor/80 cursor-pointer w-10 px-[10px] h-[40px] flex items-center justify-center rounded-lg ml-[10px]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-6 h-6 stroke-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                </svg>
              </div>
            </div>
          </div>
        }
      </div>
      {!chatid ?
        <div className='w-full flex md:hidden flex-row h-[90vh] rounded-lg border border-gray-300'>
          {user?.usertype == "Admin" || user?.usertype == "SuperAdmin" ?
            <div className='bg-white w-full'>
              <div className='bg-white relative flex justify-center py-5'>
                <input type='text' placeholder='Search'
                  value={searchtext}
                  onChange={handlesearchChange}
                  className="pl-10 text-textcolor text-sm font-medium rounded-full PoppinsRegular w-[90%] py-2 border border-themeColor items-center flex" />
                <div className='absolute left-[38px] top-[28px]'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-5 h-5 stroke-textcolor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                </div>
              </div>
              {chats.map((item, index) => (
                item.employeedata[0]?.firstname.toLowerCase().includes(searchtext.toLowerCase()) || item.employeedata[0]?.lastname.toLowerCase().includes(searchtext.toLowerCase()) ?
                  <div
                  key = {index.toString()}
                    onClick={() => {
                      updateItem('conversations', item.id, { latestmessageseen: true })
                      setchatid(item.id)
                      setchatterdata(item.employeedata[0])
                    }}
                    className='w-[100%] border-b-2 border-gray-300 flex flex-row items-center py-5 px-[10px] cursor-pointer hover:bg-themeColor text-textcolor hover:text-white'>
                    <img src={item.employeedata[0]?.profilepicture} className='w-[35px] h-[35px] object-cover rounded-full' />
                    <div className='flex flex-col px-4 w-full'>
                      <div className='flex justify-between items-start w-full'>
                        <div className='w-[70%] flex flex-col'>
                          <p className=' PoppinsRegular font-bold text-base'>{item.employeedata[0]?.firstname + " " + item.employeedata[0]?.lastname}</p>
                          <p className=' text-lighttextGray PoppinsRegular font-semibold text-xs overflow-hidden'>{item.latestmessage}</p>
                        </div>
                        <div className='w-[30%] flex flex-col justify-center items-center'>
                          <p className='text-lighttextGray PoppinsRegular h-[25px] font-semibold text-xs'>{moment(item.latestMessageTimeMs).fromNow()}</p>
                          {item.latestmessageseen == false ?
                            <div style={{
                              width: 10,
                              height: 10,
                              borderRadius: '50%',
                              backgroundColor: 'red',
                            }}>
                            </div>
                            : null
                          }
                        </div>
                      </div>

                    </div>

                  </div>
                  :
                  null
              ))}
            </div> : null
          }
        </div>
        :
        <div className='relative flex md:hidden h-[90vh] flex-col w-full bg-white '>
          <div className='px-6 py-4 flex items-center'>
            <div className='w-[70%] flex flex-row items-center'>
              {user?.usertype == "Admin" || user?.usertype == "SuperAdmin" ?
                <>
                  <div
                    className=' cursor-pointer '
                    onClick={() => {

                      setchatid("")
                      setchatterdata("")
                      setmessagearray([])
                    }}>
                    <HiArrowSmallLeft
                      className={` text-themeColor text-[25px]`}
                    />
                  </div>

                  <div className='ml-[15px]'>
                    <h1 className='PoppinsRegular text-textcolor font-bold text-2xl'>{chatterdata?.firstname + " " + chatterdata?.lastname}</h1>
                    <p className='PoppinsRegular text-textcolor/50 font-medium text-sm'>{chatterdata?.designation}</p>
                  </div>
                </> : null}
            </div>
            <div className='w-[30%] flex justify-end items-center'>
              <div className='w-10 h-10 cursor-pointer bg-[#e0e0e0] rounded-full flex justify-center items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-6 h-6 stroke-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
              </div>
              <div className='w-10 h-10 ml-3 bg-[#e0e0e0] cursor-pointer rounded-full flex justify-center items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-6 h-6 stroke-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                </svg>
              </div>
            </div>
          </div>
          <div className='w-[95%] mx-auto h-[1px] bg-gray-300'></div>
          <div className='w-[100%] pb-24 overflow-y-scroll gk-scr px-6'>
            {messagearray.map((item, index) => (
              <div
              key = {index.toString()}
              className={` my-4 flex w-full flex-col ${item.owner == user.id ? "items-end" : "items-start"}`}>
                <div className='flex max-w-[100%]'>

                  <div className={`pl-2 w-[100%] flex flex-col ${item.owner == user.id ? "items-end" : "items-start"}`}>
                    <div className={` ${item?.hasImages ? null : item.owner == user.id ? "bg-stone-100 " : "bg-themeColor text-white "} w-full px-5 py-2 rounded-full overflow-hidden`}>
                      {item?.hasImages
                        ?
                        <img src={item.image} className='w-[200px] h-[100px] object-cover' />
                        :
                        <p ref={endOfMessagesRefmobile} className='text-sm PoppinsRegular text-wrap font-medium'>{item.text}</p>
                      }
                    </div>
                    <p className={`text-[8px] PoppinsRegular  pt-1  ${item.owner == user.id ? "mr-[10px]" : "ml-[10px]"}`}>{moment(item.createdMs).fromNow()}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='w-full bg-white flex justify-center py-[20px] items-center position absolute bottom-0 px-5 z-50'>
            {picturedata ?
              <div className=" px-4 text-whitecolor text-base w-full py-2 rounded-2xl border border-bordercontainer items-center flex">
                <div className='relative w-[200px] h-[100px] '>
                  <IoIosCloseCircle
                    onClick={() => {
                      setpicture("")
                      setpicturedata("")
                    }}
                    className={`text-red-600 text-[20px] absolute -top-[5px] -right-[5px] z-10`}
                  />
                  <img src={picture} className='w-[100%] h-[100%] object-cover' />
                </div>
              </div>
              :
              <>
                <input type='text'
                  value={message}
                  placeholder='Type message'
                  onChange={handlemessageChange}
                  className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex" />
                {/* <input type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleimageselected} /> */}

                {/* <div
                  onClick={handleimageChange}
                  className='flex items-center justify-center w-[50px] h-[40px] cursor-pointer rounded-full bg-themeColor hover:bg-themeColor/80 ml-[10px]'>
                  <IoCamera
                    className={`text-white text[45px]`}
                  />
                </div> */}
              </>}
            <div
              onClick={() => {
                sendmessage()
              }}
              className='bg-themeColor hover:bg-themeColor/80 cursor-pointer w-10 px-[10px] h-[40px] flex items-center justify-center rounded-lg ml-[10px]'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" className="w-6 h-6 stroke-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
              </svg>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Messages;