import { useEffect, useRef, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { getuserinformation, updateItem } from "../../utils/firebasefunctions";
import moment from "moment";
import { RotatingLines } from 'react-loader-spinner'
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { ToastContainer, toast } from "react-toastify";

const EmployeeSlotPopUp=(props) => {
    const [employeedata, setemployeedata] = useState()

    const [loading, setloading] = useState(false)

    const popupref = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (popupref.current && !popupref.current.contains(event.target)) {
        props.activateoutsidecontainerclick()
          }


        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [popupref]);

useEffect(()=>{
    if( props.visible){
        getusersactivitylogs(props.data.recordid)
    }

}, [props.data, props.visible])

async function getusersactivitylogs(recordid){
    await getuserinformation("slots", recordid).then(async(response) => {
    setemployeedata(response)
    }).catch((err) => {
      alert(err)
    })
  }
  const Logs = (time, log) => {
    return(
        <div className="w-full flex flex-row">
                    <p className="PoppinsRegular font-medium text-lightgrey text-[15px]">{time}</p>
                    <p className="ml-[10px] PoppinsRegular font-medium text-black text-[15px]">{log}</p>
        </div>

    )
  }

  const ShiftStatus = () => {
    return(
        <div className="w-full py-[20px] px-[15px]">
            <div className="w-full flex flex-row justify-between">
                    <p className="PoppinsRegular font-medium text-black text-[15px]">Date:</p>
        <p className="PoppinsRegular font-medium text-black text-[15px]">{props.data.selecteddate}</p>
        </div>
        <div className="w-full flex flex-row justify-between">
                    <p className="PoppinsRegular font-medium text-black text-[15px]">Shift Start Time:</p>
        <p className="PoppinsRegular font-medium text-black text-[15px]">{props.data.starttime ? props.data.starttime : "Not Assigned"}</p>
        </div>
        <div className="w-full flex flex-row justify-between">
                    <p className="PoppinsRegular font-medium text-black text-[15px]">Shift End Time:</p>
        <p className="PoppinsRegular font-medium text-black text-[15px]">{props.data.endtime ? props.data.endtime : "Not Assigned"}</p>
        </div>
        {employeedata?.checkoutdatetime != undefined &&
        <div className="w-full flex flex-row justify-between">
                    <p className="PoppinsRegular font-medium text-black text-[15px] flex flex-row">Hours Worked<p className={`${employeedata?.hoursapproved == true ? "text-green-500" : employeedata?.hoursapproved == false ?  "text-red-500" : "text-black"}  ml-[5px] text-[14px]`}>{`${employeedata?.hoursapproved == true ? " (Approved):" : employeedata?.hoursapproved == false ? " (Rejected):" : ":"}`}</p></p>
        <p className={`PoppinsRegular font-medium  text-[15px] ${employeedata?.hoursapproved == true ? "text-green-500" : employeedata?.hoursapproved == false ?  "text-red-500" : "text-black"}`}>{Math.floor(((employeedata?.checkoutdatetime - employeedata?.checkindatetime) / (1000 * 60 * 60)) % 24) + " hrs "  + Math.floor(((employeedata?.checkoutdatetime - employeedata?.checkindatetime) / 1000 / 60) % 60) + " mins"}</p>
        </div>
  }

        <p className="pt-[10px] PoppinsRegular font-medium text-black">Today's Shift Logs:</p>
        {employeedata == undefined &&
            <p className="PoppinsRegular font-medium text-black text-[15px]">Not Available</p>
          }

{employeedata?.onthewaytime != undefined &&
  Logs(moment(employeedata?.onthewaytime).format("H:mm"), "Marked on the way")
}
{employeedata?.checkindatetime != undefined &&
  Logs(moment(employeedata?.checkindatetime).format("H:mm"), "Checked In")
}
{employeedata?.lunchindatetime != undefined &&
  Logs(moment(employeedata?.lunchindatetime).format("H:mm"), "Lunch In")
}
{employeedata?.lunchoutdatetime != undefined &&
  Logs(moment(employeedata?.lunchoutdatetime).format("H:mm"), "Lunch Out")
}
{employeedata?.checkoutdatetime != undefined &&
  Logs(moment(employeedata?.checkoutdatetime).format("H:mm"), "Checked Out")
}
</div>







    )
  }


  const Approvalbutton = (type) => {
    return(
  <div
  onClick={async ()=>{


    setloading(true)
    let data = {
      hoursapproved: type == "cross" ? false : true
    }
    await updateItem('slots',  props.data.recordid, data)
    .then(async response => {
      if (response == "success") {

  let dataset = {...employeedata}
  dataset["hoursapproved"] = data.hoursapproved
  setemployeedata(dataset)

  props.onapprovalsuccess(props.data.recordid, data.hoursapproved)


        setloading(false)

      } else {
        setloading(false)
        toast.warn("Something went wrong. Please try again later!")

      }
    })
    .catch(error => {
      setloading(false)

      toast.warn("Something went wrong. Please try again later!")
    });
  }}
  className="h-[40px] w-[40px] flex items-center justify-center  hover:cursor-pointer"
>

{type == "check" ?
     <IoMdCheckmark
     className='text-green-500 text-[40px]'/>
 :

     <RxCross2
     className='text-red-500 text-[40px]'/>
 }


  </div>
    )
  }
    return(
        props.visible &&
        <div

        className = "position absolute z-10  top-[0] left-[0] w-full h-full bg-[rgba(211,211,211,0.4)] flex items-center justify-center"
        >
        <div
               ref={popupref}
        className="w-[400px]   bg-white border border-gray-300  rounded-xl  gk-scr   flex items-center justify-center flex-col overflow-hidden" >

  <img src={props.data.picture} className='w-full h-[200px]  object-cover  ' />

<div className="w-full flex flex-row justify-between items-start pt-[15px]  px-[15px]">
<div className="flex flex-col ">
  <p className="PoppinsRegular font-medium text-black">{props.data.name}</p>
  <p className="PoppinsRegular font-medium text-lightgrey text-[14px]">{props.data.designation}</p>
  </div>
  <p className={`PoppinsRegular font-medium text-black ${employeedata?.checkoutdatetime ? "text-red-500" : employeedata?.checkindatetime ? "text-themeColor" : "text-orange-500"}`}>{employeedata?.checkoutdatetime ? "Checked Out" : employeedata?.checkindatetime ? "Checked In" : employeedata?.onthewaytime ? "On The Way" : "Pending"}</p>
  </div>
  {ShiftStatus()}

  { employeedata?.hoursapproved != true &&  employeedata?.hoursapproved != false &&
       <>
           {loading ?

<div className='py-[20px]'>
<RotatingLines
visible={true}
height="25"
width="25"
strokeColor="#218fdb"
color="grey"
strokeWidth="5"
animationDuration="0.75"
ariaLabel="rotating-lines-loading"
wrapperStyle={{}}
wrapperClass=""
/>
</div>:
                  <div className="flex flex-row w-full justify-evenly px-[30%] pb-[20px]">
            {Approvalbutton("cross")}
            {Approvalbutton("check")}
            </div>}


            </>

}
  {/* <p className="text-center">{props.text}</p>

<div className="flex w-full justify-between items-center flex-row mt-[10px]">
<button
                    onClick={() => {
props.accept()
                    }}
                    type='submit' className='text-center py-2 rounded-md  bg-themeColor hover:bg-themeColor/80 w-[45%]  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
                    Yes
                </button>
                <button
                    onClick={() => {
                        props.cancel()
                    }}
                    type='submit' className='text-center py-2 rounded-md  bg-lighttextGray hover:bg-lighttextGray/80 w-[45%]  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
                  No
                </button>
</div> */}
        </div>
        </div>
    )
}


export default EmployeeSlotPopUp